import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { capitalize } from "lodash";
import "react-table-6/react-table.css";
import {
  Checkbox,
  Container,
  Icon,
  Popup,
  Segment,
  Dropdown,
} from "semantic-ui-react";
import ACL_RELATIONSHIPS from "../acl-relationships";
import setPageTitle from "../helpers/title";
import CustomDropdown from "./CustomDropdown";
import AuthService from "../services/Auth";
import CallDispositionService from "../services/CallDispositions";
import CampaignService from "../services/Campaign";
import CampaignTargetCallService from "../services/CampaignTargetCall";
import ContactService from "../services/Contact";
import DialSessionService from "../services/DialSessions";
import EntityService from "../services/Entity";
import UserService from "../services/User";

import AudioElement from "./AudioElement";
import BaseTable from "./BaseTable";
import { checkIsAuthorized, formatDate } from "./helpers";
import withRoleCheck from "./hocs/withRoleCheck";
import _EditCallModal from "./modals/EditCallModal";
import _DeleteCampaignTargetCallModal from "./modals/DeleteCampaignTargetCallModal";
import TagList from "./TagList";
import callConstants from "constants/Call";
import TwilioPhoneNumberService from "services/TwilioPhoneNumber";

const EditCallModal = withRoleCheck(_EditCallModal, [
  ACL_RELATIONSHIPS.campaignTargetCall.edit,
]);

const DeleteCampaignTargetCallModal = withRoleCheck(
  _DeleteCampaignTargetCallModal,
  [ACL_RELATIONSHIPS.campaignTargetCall.delete]
);

class CallsTable extends BaseTable {
  static propTypes = {
    campaignId: PropTypes.number,
    entityId: PropTypes.number,
    contactId: PropTypes.number,
    allCalls: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.queryMethod = CampaignTargetCallService.getTable;

    this.state = {
      ...this.state,
      tableName: "calls",
      header: "Calls",
      headerIcon: "call",
      className: "CampaignTargetCall",
      noDataText: "No Calls found. Try adjusting your filters.",
      persistSelection: true,
      assignees: [],
      callStatuses: [],
      contactNumbers: [],
      internalLines: [],
      enableTags: checkIsAuthorized([
        ACL_RELATIONSHIPS.callTags.create,
        ACL_RELATIONSHIPS.callTags.read,
        ACL_RELATIONSHIPS.callTags.delete,
      ]),
      defaultFilterParams: {
        campaign_id: this.props.campaignId || undefined,
        entity_id: this.props.entityId || undefined,
        contact_id: this.props.contactId || undefined,
      },
      exportTableName: "CampaignTargetCallsTable",
    };
  }

  async componentDidMount() {
    setPageTitle("Calls");
    if (AuthService.isLoggedIn()) {
      this.fetchData();
      this.fetchStatuses();
      if (!this.props.entityId) {
        this.fetchAssignees();
      }
      this.fetchTags();
    }
  }

  setColumns = () => {
    const columns = [
      {
        resizable: false,
        sortable: false,
        headerClassName: "centered non-sortable",
        width: 40,
        className: "centered",
        Header: props => (
          <Checkbox
            onChange={this.onSelectAll}
            checked={this.state.allSelected}
          />
        ),
        Cell: ({ original: { id } }) => (
          <Checkbox
            onChange={this.handleChange}
            id={id}
            checked={this.state.checked[id]}
          />
        ),
      },
      {
        Header: "ID",
        accessor: "id",
        width: 50,
        Cell: props => <p style={{ fontWeight: "bold" }}>{props.value}</p>,
      },
      {
        Header: "Tags",
        accessor: "tags",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <TagList
            tags={props.value}
            modelType={this.state.className}
            modelId={props.original.id}
            onUpdate={this.fetchData}
            tableCell
          />
        ),
      },
      {
        Header: "Entity",
        accessor: "entity_name",
        Cell: ({ value: entity_name, original: { entity_id } }) => (
          <Link to={`/entities/${entity_id}`}>{entity_name}</Link>
        ),
      },
      {
        Header: "Assignee",
        accessor: "assignee_email",
      },
      {
        Header: "Contact ID",
        accessor: "contact_id",
        width: 110,
        Cell: props => (
          <Link to={`/contacts/${props.value}`}>{props.value}</Link>
        ),
      },
      {
        Header: "Contact Name",
        accessor: "contact_full_name",
        Cell: ({ value: contact_name, original: { contact_id } }) => (
          <>
            {contact_name ? (
              <Link to={`/contacts/${contact_id}`}>{contact_name}</Link>
            ) : (
              "Unlinked Contact"
            )}
          </>
        ),
      },
      {
        Header: "Contact Number",
        accessor: "external_number",
        sortable: false,
        headerClassName: "non-sortable",
        minWidth: 110,
        Cell: props => {
          const { contact_id } = props.original;

          return contact_id ? (
            <Link to={`/contacts/${contact_id}`}>{props.value}</Link>
          ) : (
            props.value
          );
        },
      },
      {
        Header: "Call Date and Time",
        accessor: "created_at",
        minWidth: 180,
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: "Disposition",
        accessor: "disposition_name",
        Cell: props => {
          const { parent_disposition_name, other_comment } =
            props.original?.info || {};
          const { status } = props.original;
          if (props.value) {
            return props.value;
          } else if (other_comment) {
            return parent_disposition_name
              ? `Other (${parent_disposition_name}): ${other_comment}`
              : `Other: ${other_comment}`;
          } else if (status) {
            return (
              <Popup
                content="No disposition set. Twilio Status is displayed"
                trigger={
                  <span style={{ fontStyle: "italic" }}>[{status}]</span>
                }
              />
            );
          }
          return null;
        },
      },
      {
        Header: "Agent Hung Up",
        accessor: "agent_hungup",
        Cell: props => {
          return (
            <div style={{ textAlign: "center", minWidth: "25px" }}>
              <Icon name={props.value ? "check" : "close"} />
            </div>
          );
        },
      },
      {
        Header: "Verified",
        accessor: "status_verified",
        Cell: props => {
          return (
            <div style={{ textAlign: "center" }}>
              <Icon name={props.value ? "check" : "close"} />
            </div>
          );
        },
      },
      {
        Header: "Recording",
        sortable: false,
        headerClassName: "non-sortable",
        accessor: "recording.url",
        minWidth: 310,
        Cell: ({ index, value, original: { info } }) =>
          (value || info.recording_info.recording_url) && (
            <AudioElement
              id={`audio-el-${index}`}
              src={value || info.recording_info.recording_url}
            />
          ),
      },
      {
        Header: "Agent",
        accessor: "agent_full_name",
      },
      {
        Header: "Duration",
        accessor: "duration",
      },
      {
        Header: "Internal Line",
        accessor: "internal_number",
      },
      {
        Header: "Dial Session Name",
        accessor: "dial_session_name",
      },
      {
        Header: "Call Direction",
        accessor: "call_direction",
        Cell: ({ value }) => capitalize(value),
      },
      {
        Header: "Manual",
        accessor: "is_manual",
        Cell: props => {
          return (
            <div style={{ textAlign: "center" }}>
              {props.value && <Icon name="check" />}
            </div>
          );
        },
      },
      {
        Header: "Actions",
        id: "actions",
        sortable: false,
        headerClassName: "non-sortable",
        minWidth: 200,
        Cell: props => {
          return (
            <CustomDropdown icon="ellipsis horizontal">
              <Dropdown.Menu direction="left">
                <EditCallModal
                  call={props.original}
                  callIndex={props.index}
                  callStatuses={this.state.callStatuses}
                  onSuccess={this.onSuccess}
                />
                {props.original.is_manual && (
                  <DeleteCampaignTargetCallModal
                    callId={props.original.id}
                    fetchData={this.fetchData}
                    menuTrigger
                  />
                )}
              </Dropdown.Menu>
            </CustomDropdown>
          );
        },
      },
    ];

    if (!this.props.campaignId) {
      columns.splice(3, 0, {
        Header: "Campaign",
        accessor: "campaign_name",
        Cell: ({ value: campaign_name, original: { campaign_id } }) => (
          <Link to={`/campaigns/${campaign_id}/calls`}>{campaign_name}</Link>
        ),
      });
    }

    this.initTableSettings(columns);
  };

  updateFilterOptions = async () => {
    const { assignees, tags } = this.state;
    const callDirectionOptions = callConstants.callDirections.map(cd => ({
      id: cd,
      name: capitalize(cd),
    }));

    let filters = [
      {
        key: "call_datetime",
        title: "Call Date and Time",
        type: "dateRange",
        includeTime: true,
      },
      {
        key: "dial_session_id",
        title: "Dial Session",
        type: "ruvixxSelect",
        queryFn: DialSessionService.getDialSessionsForFilters,
        props: {
          campaignIds: [this.props.campaignId],
        },
      },
      {
        key: "contact_id",
        title: "Contact",
        type: "ruvixxSelect",
        queryFn: ContactService.getContactsForFilters,
        props: {
          campaignIds: [this.props.campaignId],
        },
      },
      {
        key: "agent_id",
        title: "Agent",
        type: "select",
        data: assignees,
      },
      {
        key: "contact_number",
        title: "Contact Number",
        type: "ruvixxSelect",
        queryFn: this.fetchNumbers,
      },
      {
        key: "caller_id",
        title: "Internal Line",
        type: "ruvixxSelect",
        queryFn: this.fetchInternalLines,
      },
      {
        key: "disposition_id",
        title: "Call Disposition",
        type: "ruvixxSelect",
        queryFn: async filters => {
          if (filters["model_id"]?.includes("Null")) {
            filters["model_id"] = filters["model_id"].filter(
              id => id !== "Null"
            );
            if (!filters["model_id"].length) {
              delete filters["model_id"];
            }
          }
          return await CallDispositionService.getDispositionsForFilters(
            filters
          );
        },
        props: {
          includeNoneOption: true,
        },
      },
      {
        key: "status_verified",
        title: "Status Verified",
        type: "select",
        data: [
          { id: "verified", name: "Verified" },
          { id: "unverified", name: "Not Verified" },
        ],
      },
      {
        key: "duration",
        title: "Duration",
        type: "range",
      },
      {
        key: "tag_id",
        title: "Tag",
        type: "select",
        data: tags,
      },
      {
        key: "recording",
        title: "Recording",
        type: "select",
        data: [
          { id: "yes", name: "Has" },
          { id: "no", name: "Doesn't have" },
        ],
        multiple: false,
        clearable: true,
      },
      {
        key: "agent_hungup",
        title: "Agent Hung Up",
        type: "ruvixxToggle",
      },
      {
        key: "call_direction",
        title: "Call Direction",
        type: "select",
        data: callDirectionOptions,
        multiple: false,
        clearable: true,
      },
      {
        title: "Is Manual",
        key: "grid_1",
        type: "grid",
        props: {
          columns: 2,
          style: { width: "50%" },
        },
        componentProps: {
          type: "checkbox",
        },
        components: [
          { key: "manual", title: "Manual" },
          { key: "not_manual", title: "Not Manual" },
        ],
      },
    ];
    if (!this.props.entityId) {
      filters.splice(3, 0, {
        key: "entity_id",
        title: "Entity",
        type: "ruvixxSelect",
        queryFn: EntityService.getEntitiesForFilters,
        props: {
          campaignIds: [this.props.campaignId],
        },
      });
      filters.splice(7, 0, {
        key: "assigned_user_id",
        title: "Assignee",
        type: "select",
        data: assignees,
      });
    }
    if (!this.props.campaignId) {
      filters.splice(3, 0, {
        key: "campaign_id",
        title: "Campaign",
        type: "ruvixxSelect",
        queryFn: async (filters = null) => {
          if (this.props.entityId) {
            filters["entity_id"] = this.props.entityId;
          }
          if (this.props.contactId) {
            filters["contact_id"] = this.props.contactId;
          }
          return await CampaignService.getCampaignsForFilters(filters);
        },
      });
    }
    this.setState({ filters });
  };

  fetchNumbers = async () => {
    try {
      const numberData = await CampaignTargetCallService.getNumberInfo();
      const contactNumbers = numberData.numbers.map(data => ({
        id: data,
        name: data,
        value: data,
      }));
      return contactNumbers;
    } catch (error) {
      console.error("Error fetching numbers:", error.message);
      return [];
    }
  };

  fetchInternalLines = async () => {
    try {
      const internalLinesData = await TwilioPhoneNumberService.getTable();
      const internalLines = internalLinesData.data.map(data => ({
        id: data.number,
        name: data.number,
        value: data.number,
      }));
      return internalLines;
    } catch (error) {
      console.error("Error fetching internal lines:", error.message);
      return [];
    }
  };

  fetchAssignees = async () => {
    const rawUsers = await UserService.getUsers();
    const assignees = rawUsers.map(user => ({
      id: user.id,
      name: user.full_name,
    }));
    this.setState({ assignees }, this.updateFilterOptions);
  };

  fetchStatuses = async () => {
    let callStatuses = await CallDispositionService.getDispositionsForFilters();
    this.setState({ callStatuses: callStatuses }, this.updateFilterOptions);
  };

  // callback method for edit call status
  onSuccess = async (call, index) => {
    const { rows } = this.state;
    rows.splice(index, 1, call);
    this.setState({ rows });
    this.fetchData();
  };

  render() {
    if (this.props.topLevel) {
      return (
        <Container fluid className="route">
          <div className="campaign-segment">
            <Segment>{this.renderTable()}</Segment>
          </div>
        </Container>
      );
    } else {
      return this.renderTable();
    }
  }
}

export default CallsTable;
