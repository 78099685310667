import React, { useCallback, useEffect, useMemo, useState } from "react";
import RuvixxSelect from "components/RuvixxSelect";
import {
  Divider,
  Form,
  FormField,
  Icon,
  Input,
  List,
  Segment,
} from "semantic-ui-react";
import Datetime from "react-datetime";
import CustomFieldForm from "../../components/forms/CustomFieldForm";
import RevenueOpportunityService from "../../services/RevenueOpportunities";
import RecipientService from "services/Recipient";
import {
  checkIsCustomFieldsReady as checkIsCustomFieldIsReady,
  extractCustom,
  getCustomFieldsForSubmit,
} from "../helpers";
import RuvixxForm from "../RuvixxForm";
import isNil from "lodash/isNil";
import EntityService from "services/Entity";
import CaseService from "services/Case";
import CampaignService from "../../services/Campaign";
import UserService from "services/User";
import { useFirstPathSegment } from "helpers/location";
import revOppsConstants from "constants/RevenueOpportunity";
import { capitalize } from "components/helpers";
import RevenueOpportunityInvoices from "components/RevenueOpportunityInvoices";
import UploadManager from "../../components/UploadManager";
import moment from "moment";
import { useQuery } from "react-query";
import UploadProgressRing from "../UploadProgressRing";
import Dropzone from "react-dropzone";
import AttachmentService from "../../services/Attachment";

import "styles/datetime.scss";

const mapOptions = ({ id, name }) => ({ id, text: name, value: id });

const dateFields = [
  ["effective_date", true],
  ["invoice_date", false],
  ["sales_order_date", false],
];

const useStatusPreset = (campaignId, statusType) => {
  const queryKey = useMemo(
    () => [statusType, { campaignId }],
    [statusType, campaignId]
  );

  const queryFn = useCallback(async () => {
    if (campaignId) {
      return await CampaignService.getStatusPreset(campaignId, statusType);
    } else {
      return undefined;
    }
  }, [campaignId, statusType]);

  const { data: statusPreset } = useQuery(queryKey, queryFn);

  return statusPreset;
};

const useRevenueOpportunityStatuses = () => {
  const { data: revenueOpportunityStatuses } = useQuery(
    ["revenueOpportunityStatuses"],
    RevenueOpportunityService.getRevenueOpportunityStatusesForFilters
  );
  return revenueOpportunityStatuses;
};

const useStatusOptions = campaignId => {
  const allOptions = useRevenueOpportunityStatuses();
  const revenueOpportunityPreset = useStatusPreset(
    campaignId,
    "revenue_status"
  );
  return useMemo(() => {
    const revenueStatusPresetAvailable = revenueOpportunityPreset ?? false;
    const revenueOpStatuses = revenueStatusPresetAvailable
      ? revenueOpportunityPreset.info.revenue_opportunity_statuses
      : [];
    const noPresetStatuses = !revenueOpStatuses?.length && !!allOptions;
    const options = noPresetStatuses ? allOptions : revenueOpStatuses;
    return (options || []).map(mapOptions);
  }, [campaignId, revenueOpportunityPreset?.id, allOptions]);
};

export default function RevenueOpportunityForm({
  revenueOpportunity,
  entityId,
  campaignId,
  caseId,
  customFieldConfigs,
  setCustomFieldConfigs,
  updateData,
  onSuccess,
  isClone,
}) {
  const [formData, setFormData] = useState({
    entity_id: +entityId || null,
    campaign_id: +campaignId || null,
    case_id: caseId || null,
    status_id: revenueOpportunity?.status_id || null,
    value:
      !isNil(revenueOpportunity?.value) && revenueOpportunity?.value !== ""
        ? revenueOpportunity?.value
        : null,
    recipient_type: revenueOpportunity?.recipient_type,
    recipient_id: revenueOpportunity?.recipient_id,
    billing_to: revenueOpportunity?.billing_to,
    purchase_order: revenueOpportunity?.purchase_order || [],
    invoice: revenueOpportunity?.invoice || [],
    invoice_date: revenueOpportunity?.invoice_date,
    sales_order: revenueOpportunity?.sales_order || [],
    sales_order_date: revenueOpportunity?.sales_order_date || "",
    comments: revenueOpportunity?.comments || "",
    invoices: revenueOpportunity?.invoices,
    effective_date: revenueOpportunity?.effective_date,
    attachments: revenueOpportunity?.attachments || [],
    manager_id: revenueOpportunity?.manager_id,
  });
  const [custom, setCustom] = useState([]);
  const [recipientTypeOptions, setRecipientTypeOptions] = useState([]);
  const [files, setFiles] = useState([]);
  const uploadManager = useMemo(() => new UploadManager(), []);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const currentPage = useFirstPathSegment();
  const statusOptions = useStatusOptions(formData.campaign_id);

  const getDefaultMainOption = () => {
    if (currentPage === "revenue_opportunities") {
      return revenueOpportunity?.id ? "entity_id" : null;
    }
    return null;
  };

  const [mainOption, setMainOption] = useState(getDefaultMainOption());
  const [mainOptionValue, setMainOptionValue] = useState();

  useEffect(() => {
    if (formData.status_id) {
      const statusNotInOptions =
        statusOptions.length &&
        !statusOptions.some(option => option.value === formData.status_id);
      if (statusNotInOptions) {
        setFormData({ ...formData, status_id: null });
      }
    }
  }, [statusOptions]);

  useEffect(() => {
    switch (currentPage) {
      case "cases":
        setMainOption("case_id");
        break;
      case "entities":
        setMainOption("entity_id");
        break;
      case "campaigns":
        setMainOption("campaign_id");
        break;
    }

    if (revenueOpportunity) {
      setCustom(extractCustom(revenueOpportunity.custom_fields));
    }
    prepareRecipientTypeOptions();
  }, []);

  useEffect(() => {
    if (mainOption === "case_id") {
      updateEntityId();
    }
    if (mainOption === "campaign_id" && currentPage !== "campaigns") {
      updateFormData({ entity_id: null });
    }
  }, [mainOption, mainOptionValue]);

  useEffect(() => {
    if (mainOption === "case_id") {
      updateFormData({ campaign_id: null });
    } else if (mainOption === "campaign_id") {
      updateFormData({ case_id: null });
    }
  }, [formData.entity_id]);

  const uploadFiles = async (filesToUpload = files) => {
    if (filesToUpload?.length) {
      await uploadManager.uploadFiles(filesToUpload);
      return uploadManager.uploads.map(upload => upload.attachment);
    } else {
      return [];
    }
  };

  const deleteAttachment = async id => {
    await RevenueOpportunityService.removeRevenueOpportunityAttachment(
      revenueOpportunity.id,
      id
    );
    await AttachmentService.delete(id);
    formData["attachments"] = formData["attachments"].filter(
      attachment => attachment.id !== id
    );
    setFormData({ ...formData });
  };

  const handleDrop = async newFiles => {
    setFiles(prevFiles => [...prevFiles, ...newFiles]);
  };

  const deleteFile = async file => {
    let copy = [...files];
    copy.splice(copy.indexOf(file), 1);
    setFiles(copy);
  };

  const handleSubmit = async e => {
    parseDateFields(formData);
    formData["custom_fields"] = getCustomFieldsForSubmit(
      customFieldConfigs,
      custom
    );
    formData["value"] = parseFloat(formData["value"]);
    // cleanup revenue opportunity invoices
    formData["invoices"] = formData["invoices"].map(invoice => {
      const newInvoice = { ...invoice };
      delete newInvoice.product_name;
      if (newInvoice.is_discount || newInvoice.is_penalty) {
        newInvoice.product_id = null;
        newInvoice.quantity = null;
        newInvoice.term = null;
      } else {
        newInvoice.quantity = parseInt(newInvoice.quantity);
        newInvoice.term = parseInt(newInvoice.term);
      }
      newInvoice.amount = parseFloat(newInvoice.amount);
      return newInvoice;
    });

    setIsSubmitting(true);

    const formDataCopy = { ...formData };

    const filesToUpload = await getFilesToUpload();
    const attachments = await uploadFiles(filesToUpload);

    formDataCopy["attachments"] = attachments;

    if (isClone) {
      delete formDataCopy.id;
      formDataCopy.invoices = formDataCopy.invoices.map(invoice => {
        delete invoice.id;
        return invoice;
      });
      await RevenueOpportunityService.createRevenueOpportunity({
        ...formDataCopy,
      });
    } else if (revenueOpportunity && revenueOpportunity.id) {
      await RevenueOpportunityService.editRevenueOpportunity(
        revenueOpportunity.id,
        formDataCopy
      );
    } else {
      await RevenueOpportunityService.createRevenueOpportunity(formDataCopy);
    }
    if (updateData) {
      await updateData();
    }
  };

  const prepareRecipientTypeOptions = () => {
    const options = Object.keys(revOppsConstants.recipientType).map(
      (option, index) => ({
        id: index,
        text: capitalize(revOppsConstants.recipientType[option]),
        value: revOppsConstants.recipientType[option],
      })
    );
    setRecipientTypeOptions(options);
  };

  const getFilesToUpload = async () => {
    const filesToUpload = [];

    const shouldDuplicateAttachments = isClone && formData.attachments.length;
    if (shouldDuplicateAttachments) {
      const downloadedFiles = await Promise.all(
        formData.attachments.map(async attachment => {
          const response = await fetch(attachment.url);
          const blob = await response.blob();
          return new File([blob], attachment.file_name, { type: blob.type });
        })
      );
      filesToUpload.push(...downloadedFiles);
    }

    filesToUpload.push(...files);
    return filesToUpload;
  };

  const fetchCampaignOptions = useCallback(
    async filters => {
      if (formData.campaign_id) {
        filters["campaign_ids"] = formData.campaign_id;
      }

      if (formData.entity_id) {
        filters["entity_id"] = formData.entity_id;
      } else {
        filters["has_entities"] = true;
      }

      if (formData.case_id) {
        filters["case_id"] = formData.case_id;
      }

      return await CampaignService.getCampaignsForFilters(filters);
    },
    [formData.campaign_id, formData.entity_id, formData.case_id]
  );

  const fetchEntityOptions = useCallback(
    async filters => {
      if (formData.campaign_id) {
        filters["campaign_ids"] = formData.campaign_id;
      } else {
        filters["has_campaigns"] = true;
      }
      if (formData.entity_id) {
        filters["entity_id"] = formData.entity_id;
      }
      if (formData.case_id) {
        filters["case_id"] = formData.case_id;
      }

      return await EntityService.getEntitiesForFilters(filters);
    },
    [formData.campaign_id, formData.entity_id, formData.case_id]
  );

  const fetchCaseOptions = useCallback(
    async filters => {
      if (formData.entity_id) {
        filters["entity_id"] = formData.entity_id;
      } else {
        filters["has_entity"] = true;
      }
      if (formData.campaign_id) {
        filters["campaign_id"] = formData.campaign_id;
      } else {
        filters["has_campaigns"] = true;
      }
      if (formData.case_id) {
        filters["case_id"] = formData.case_id;
      }

      return await CaseService.getForFilters(filters);
    },
    [formData.case_id, formData.entity_id, formData.campaign_id]
  );

  const fetchRecipientOptions = useCallback(
    async filters => {
      if (formData.recipient_type) {
        filters["types"] = formData.recipient_type;
      }
      return await RecipientService.getForFilters(filters);
    },
    [formData.recipient_type]
  );

  const fetchRevenueOpportunityManagerOptions = useCallback(async filters => {
    if (!filters) filters = {};
    delete filters.model_id;
    filters.with_deleted = true;
    let revenueOpportunityManagers = await UserService.getUsersForFilters(
      filters
    );

    return revenueOpportunityManagers.filter(
      user => user.id > 0 && (user.id === formData.manager_id || !user.deleted)
    );
  }, []);

  const handleChangeMainOption = async (_, { name, value }) => {
    if (!name) {
      return;
    }

    const formUpdates = {};

    formUpdates[name] = value;

    if (name === "entity_id") {
      formUpdates["campaign_id"] = null;
      formUpdates["case_id"] = null;
    }

    if (!value) {
      setMainOption(null);
      setMainOptionValue(null);

      formUpdates["entity_id"] = null;
      formUpdates["campaign_id"] = null;
      formUpdates["case_id"] = null;
    } else {
      setMainOption(name);
      setMainOptionValue(value || null);
    }

    updateFormData(formUpdates);
  };

  const handleChangeSecondOption = async (_, { name, value }) => {
    if (!name) {
      return;
    }
    updateFormData({ [name]: value || null });
  };

  const updateFormData = newValues => {
    setFormData({
      ...formData,
      ...newValues,
    });
  };

  const updateEntityId = useCallback(async () => {
    if (formData.case_id && mainOption === "case_id") {
      const caseData = await CaseService.get(formData.case_id);
      updateFormData({ entity_id: caseData.entity_id });
    }
  }, [formData.case_id]);

  const checkInvoiceLicenseDates = (licenseStartDate, licenseEndDate) => {
    const isDateValid = value => {
      const date = moment(value, "YYYY-MM-DD", true);
      return date.isValid();
    };

    if (!licenseStartDate && !licenseEndDate) {
      return true;
    }

    if (!licenseStartDate && licenseEndDate) {
      return false;
    }

    if (licenseStartDate && !licenseEndDate) {
      return isDateValid(licenseStartDate);
    }

    if (licenseStartDate && licenseEndDate) {
      return isDateValid(licenseStartDate) && isDateValid(licenseEndDate);
    }
  };

  const checkIsInvoicesReady = () => {
    return formData.invoices?.every(invoice => {
      if (invoice.is_penalty || invoice.is_discount) {
        return Boolean(invoice.amount);
      } else {
        const areLicenseDatesValid = checkInvoiceLicenseDates(
          invoice.license_start_date,
          invoice.license_end_date
        );
        return (
          areLicenseDatesValid &&
          Boolean(invoice.term) &&
          Boolean(invoice.quantity)
        );
      }
    });
  };

  const checkIfRecipientIsReady = () => {
    if (!formData.recipient_type) return false;

    if (formData.recipient_type !== revOppsConstants.recipientType.CLIENT) {
      return Boolean(formData.recipient_id);
    }
    return true;
  };

  const handleChange = async (_, { name, value }) => {
    if (!name) {
      return;
    }
    if (name === "status_id") {
      setFormData({
        ...formData,
        [name]: value,
        effective_date: null,
      });
    } else if (name === "recipient_type") {
      setFormData({
        ...formData,
        [name]: value,
        recipient_id: null,
      });
    } else {
      if (name === "recipient_id" && !value) {
        value = null;
      }
      const formDataCopy = { ...formData };
      formDataCopy[name] = value;
      setFormData(formDataCopy);
    }
  };

  const checkDateFields = () => {
    for (const [field, required] of dateFields) {
      let value = formData[field];
      if (!value) {
        if (required) {
          return false;
        }
        continue;
      }
      if (!moment.isMoment(value)) {
        value = moment(value, "YYYY-MM-DD", true);
      }
      if (!value.isValid()) {
        return false;
      }
    }
    return true;
  };

  const parseDateFields = formData => {
    for (const [field] of dateFields) {
      let value = formData[field];
      if (value) {
        value = moment(value).format("YYYY-MM-DD");
      }
      formData[field] = value;
    }
  };

  const handleUpdateCustomFieldConfigs = customFieldConfigs => {
    setCustomFieldConfigs(customFieldConfigs);
    setCustom(
      customFieldConfigs
        .filter(cfc => cfc.required || cfc.selected)
        .map(cfc => ({ key: cfc.label, value: cfc.value }))
    );
  };

  const getMainOptions = () => {
    const canShowSelect = selectName => {
      if (!mainOption) return true;

      if (selectName === "entity_id") {
        return mainOption !== "campaign_id";
      }

      return mainOption === selectName;
    };

    return (
      <>
        {canShowSelect("entity_id") && (
          <RuvixxSelect
            label="Entity"
            required
            readOnly={
              (mainOption && mainOption !== "entity_id") ||
              currentPage === "entities"
            }
            placeholder="Pick an Entity"
            name="entity_id"
            value={formData.entity_id}
            onChange={handleChangeMainOption}
            queryFn={fetchEntityOptions}
          />
        )}

        {canShowSelect("campaign_id") && (
          <RuvixxSelect
            label="Campaign"
            readOnly={currentPage === "campaigns"}
            clearable
            placeholder="Pick a Campaign"
            name="campaign_id"
            value={formData.campaign_id}
            onChange={handleChangeMainOption}
            queryFn={fetchCampaignOptions}
          />
        )}

        {canShowSelect("case_id") && (
          <RuvixxSelect
            readOnly={currentPage === "cases"}
            label="Case"
            clearable
            placeholder="Pick a Case"
            name="case_id"
            value={formData.case_id}
            onChange={handleChangeMainOption}
            queryFn={fetchCaseOptions}
          />
        )}
        <Divider />
      </>
    );
  };

  const getSecondaryOptions = () => {
    const canShowSelect = selectName => {
      if (!mainOption) return false;

      switch (selectName) {
        case "entity_id":
          return mainOption === "campaign_id";
        case "campaign_id":
          return mainOption !== "campaign_id";
        case "case_id":
          return mainOption !== "case_id";
      }
    };

    return (
      <>
        {canShowSelect("entity_id") && (
          <RuvixxSelect
            label="Entity"
            required
            placeholder="Pick an Entity"
            name="entity_id"
            value={formData.entity_id}
            onChange={handleChangeSecondOption}
            queryFn={fetchEntityOptions}
          />
        )}

        {canShowSelect("campaign_id") && (
          <RuvixxSelect
            label="Campaign"
            clearable
            placeholder="Pick a Campaign"
            name="campaign_id"
            value={formData.campaign_id}
            onChange={handleChangeSecondOption}
            queryFn={fetchCampaignOptions}
          />
        )}

        {canShowSelect("case_id") && (
          <RuvixxSelect
            label="Case"
            clearable
            placeholder="Pick a Case"
            name="case_id"
            value={formData.case_id}
            onChange={handleChangeSecondOption}
            queryFn={fetchCaseOptions}
          />
        )}
      </>
    );
  };

  const renderAttachments = () => {
    const { attachments } = formData;
    return (
      <>
        {attachments.length > 0 ? (
          <Form.Field label="Previous Attachments" />
        ) : null}
        <List relaxed>
          {attachments.length > 0
            ? attachments.map((file, i) => {
                return (
                  <List.Item key={i}>
                    <List.Content floated="right">
                      <Icon
                        name="delete"
                        onClick={() => deleteAttachment(file.id)}
                      />
                    </List.Content>
                    <a
                      href={file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {file.file_name}
                    </a>
                  </List.Item>
                );
              })
            : null}
        </List>
        {attachments.length > 0 ? (
          <Form.Field label="New Attachments" />
        ) : (
          <Form.Field label="Attachments" />
        )}
        {isSubmitting && files && files.every(f => f.file) ? (
          <UploadProgressRing uploads={files} />
        ) : (
          <List relaxed>
            {!!files
              ? files.map((file, i) =>
                  file !== undefined ? (
                    <List.Item key={i}>
                      <List.Content floated="right">
                        <Icon name="delete" onClick={() => deleteFile(i)} />
                      </List.Content>
                      {file.name}
                    </List.Item>
                  ) : null
                )
              : null}
          </List>
        )}
        <Dropzone onDrop={files => handleDrop(files)}>
          {({ getRootProps, getInputProps }) => (
            <Segment style={{ border: "dashed 2px grey", textAlign: "center" }}>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </div>
            </Segment>
          )}
        </Dropzone>
      </>
    );
  };

  return (
    <RuvixxForm
      ready={
        !!formData.entity_id &&
        !!formData.status_id &&
        !!formData.manager_id &&
        (formData.value || formData.value === 0) &&
        checkIsCustomFieldIsReady(customFieldConfigs) &&
        checkIfRecipientIsReady() &&
        checkIsInvoicesReady() &&
        checkDateFields()
      }
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
    >
      {getMainOptions()}
      {getSecondaryOptions()}
      <RuvixxSelect
        required
        label="Manager"
        value={formData.manager_id}
        placeholder="Pick a Manager"
        name="manager_id"
        onChange={handleChangeSecondOption}
        queryFn={fetchRevenueOpportunityManagerOptions}
      />
      <Form.Select
        inline
        label="Recipient Type"
        placeholder="Pick a Recipient Type"
        name="recipient_type"
        required
        value={formData.recipient_type}
        onChange={handleChange}
        options={recipientTypeOptions}
      />

      <RuvixxSelect
        label="Recipient Name"
        clearable={
          formData.recipient_type === revOppsConstants.recipientType.CLIENT
        }
        readOnly={!formData.recipient_type}
        required={
          formData.recipient_type !== revOppsConstants.recipientType.CLIENT
        }
        placeholder="Pick a Recipient"
        name="recipient_id"
        value={formData.recipient_id}
        onChange={handleChange}
        queryFn={fetchRecipientOptions}
        additionalItems={
          revenueOpportunity?.recipient_id
            ? [
                {
                  id: revenueOpportunity.recipient_id,
                  name: revenueOpportunity.recipient_name,
                },
              ]
            : []
        }
      />

      <Form.Input
        inline
        name="billing_to"
        label="Billing To"
        value={formData.billing_to}
        onChange={handleChange}
      />

      <Form.Select
        inline
        clearable
        search
        multiple
        name="purchase_order"
        label="P.O.#"
        options={formData.purchase_order?.map(val => ({
          key: val,
          value: val,
          text: val,
        }))}
        value={formData.purchase_order}
        onChange={handleChange}
        allowAdditions
      />

      <Form.Group inline>
        <Form.Select
          inline
          clearable
          search
          multiple
          name="invoice"
          label="Invoice #"
          options={formData.invoice?.map(val => ({
            key: val,
            value: val,
            text: val,
          }))}
          value={formData.invoice}
          onChange={handleChange}
          allowAdditions
        />

        <FormField inline>
          <label>Invoice Date</label>
          <Datetime
            className="ui input"
            closeOnSelect={true}
            timeFormat={false}
            value={formData.invoice_date}
            onChange={value => {
              handleChange(null, { name: "invoice_date", value });
            }}
            renderInput={props => <Input icon="calendar outline" {...props} />}
            dateFormat={"YYYY-MM-DD"}
          />
        </FormField>
      </Form.Group>

      <Form.Group>
        <Form.Select
          inline
          clearable
          search
          multiple
          name="sales_order"
          label="S.O.#"
          options={formData.sales_order?.map(val => ({
            key: val,
            value: val,
            text: val,
          }))}
          value={formData.sales_order}
          onChange={handleChange}
          allowAdditions
        />

        <FormField inline>
          <label>S.O. Date</label>
          <Datetime
            className="ui input"
            closeOnSelect={true}
            timeFormat={false}
            value={formData.sales_order_date}
            onChange={value => {
              handleChange(null, { name: "sales_order_date", value });
            }}
            renderInput={props => <Input icon="calendar outline" {...props} />}
            dateFormat={"YYYY-MM-DD"}
          />
        </FormField>
      </Form.Group>

      <Form.Group>
        <Form.Select
          inline
          placeholder="Pick a Revenue Opportunity Status"
          label="Status"
          name="status_id"
          value={formData.status_id}
          onChange={handleChange}
          options={statusOptions}
          setValue={() => {}}
          required
        />
        <Form.Field inline required className="dateTime">
          <label>Effective Date</label>
          <Datetime
            closeOnSelect
            timeFormat={false}
            value={formData.effective_date}
            onChange={value => {
              handleChange(null, { name: "effective_date", value });
            }}
            renderInput={props => <Input icon="calendar outline" {...props} />}
            dateFormat="YYYY-MM-DD"
          />
        </Form.Field>
      </Form.Group>

      <RevenueOpportunityInvoices
        revenueOpportunity={revenueOpportunity}
        formData={formData}
        updateFormData={updateFormData}
      />
      {renderAttachments()}
      {!!customFieldConfigs && (
        <CustomFieldForm
          modelType="RevenueOpportunity"
          customFields={custom}
          customFieldConfigs={customFieldConfigs}
          onUpdateCustomFieldConfigs={handleUpdateCustomFieldConfigs}
        />
      )}
    </RuvixxForm>
  );
}
