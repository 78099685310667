import React, { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import {
  Button,
  Checkbox,
  Form,
  Grid,
  Header,
  List,
  Message,
  Modal,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import EntityService from "../../../services/Entity";
import CampaignService from "../../../services/Campaign";
import "./MergeEntitiesModal.scoped.scss";
import { formatAddress } from "../../../helpers/address";

const VALUE_KEY_MAP = {
  tags: "name",
  account_managers: "user_full_name",
  campaigns: "campaign_id",
};

function EntityInfo({
  entity,
  infoType,
  select = false,
  header = undefined,
  handleChange,
  mergedEntity = {},
}) {
  const {
    id,
    name,
    urls,
    addresses,
    account_managers,
    campaigns,
    tags,
    custom_fields,
    alternate_names,
  } = entity;

  let info;

  switch (infoType) {
    case "campaigns-tags":
      info = [
        {
          title: "Campaigns",
          type: "campaigns",
          value: campaigns,
        },
        { title: "Tags", type: "tags", value: tags.filter(tag => !tag.auto) },
      ];
      break;
    case "customfields":
      info = [
        {
          title: "Custom Fields",
          type: "custom",
          value: Object.keys(custom_fields).map(k => ({
            field: k,
            value: custom_fields[k],
            entity_id: id,
          })),
        },
      ];
      break;
    case "basic-info":
    default:
      info = [
        { title: "Name", type: "name", value: name },
        { title: "URLs", type: "urls", value: urls || [] },
        {
          title: "Addresses",
          type: "addresses",
          value: addresses || [],
        },
        {
          title: "Alternate Names",
          type: "alternate_names",
          value: alternate_names || [],
        },
        {
          title: "Account Managers",
          type: "account_managers",
          value: account_managers || [],
        },
      ];
  }

  const checkBoxName = (value, type) => {
    let id = value.id || value.user_id || value.entity_id;
    return `${type}${id ? `-${id}` : ""}`;
  };

  const checkBoxValue = (value, subvalue) => {
    let val =
      value.name || value.email || value.user_full_name || value.field || value;
    if (subvalue) {
      val += `|${subvalue}`;
    }
    return val;
  };

  const itemText = (value, type) => {
    switch (type) {
      case "tags":
      case "account_managers":
        return `${value[VALUE_KEY_MAP[type]]}`;
      case "addresses":
        return formatAddress(value);
      case "campaigns":
        return value.name;
      case "custom":
        return `${value.field}: ${
          Array.isArray(value.value) ? "" : value.value
        }`;
      case "alternate_names":
        return value.name;
      default:
        return value;
    }
  };

  const customList = (value, type) => {
    return (
      <div className="custom-list">
        {value.value.toSorted().map((val, index) => (
          <p key={index}>
            {select && (
              <Checkbox
                onChange={handleChange}
                name={checkBoxName(value, type)}
                checked={isChecked(value, type, val)}
                value={checkBoxValue(value.field, val)}
              />
            )}{" "}
            {val}
          </p>
        ))}
      </div>
    );
  };

  const isChecked = (value, type, subvalue) => {
    if (Array.isArray(mergedEntity[type])) {
      if (value.id) {
        return !!mergedEntity[type].find(v => v.id === value.id);
      }
      if (value.user_id) {
        return !!mergedEntity[type].find(v => v.user_id === value.user_id);
      }
      return !!mergedEntity[type].find(v => {
        return v === value;
      });
    } else {
      if (type === "custom") {
        if (Array.isArray(value.value)) {
          const customFields = mergedEntity.custom_fields?.[value.field] ?? [];
          if (subvalue) {
            return customFields.includes(subvalue);
          }
          const allOn = value.value.every(val => customFields.includes(val));
          const allOff = value.value.every(val => !customFields.includes(val));
          return !allOn && !allOff ? "indeterminate" : allOn;
        }
        return (
          mergedEntity.custom_fields &&
          mergedEntity.custom_fields[value.field] !== undefined &&
          mergedEntity.custom_fields[value.field] !== null &&
          mergedEntity.custom_fields[value.field] === value.value
        );
      }
      return mergedEntity[type] === value;
    }
  };

  return (
    <div className="entity-info">
      {!!entity.id ? (
        <>
          {!!header && (
            <Header as="h4" block>
              {header}
            </Header>
          )}
          <List>
            {info
              .map(item => ({
                ...item,
                value: Array.isArray(item.value)
                  ? item.value
                  : item.value
                  ? [item.value]
                  : [],
              }))
              .map(({ title, value, type }, index) => {
                value = value.filter(
                  v => v && (!Array.isArray(v.value) || v.value.length > 0)
                ); // remove empty or null values
                return (
                  <List.Item key={index}>
                    <List.Header>{title}</List.Header>
                    <List.Content>
                      {value.length > 0 ? (
                        value.map((val, index) => {
                          const checked = isChecked(val, type);
                          return (
                            <div key={index} className={`info-item ${type}`}>
                              <div>
                                <p>
                                  {select && (
                                    <Checkbox
                                      onChange={handleChange}
                                      name={checkBoxName(val, type)}
                                      checked={checked}
                                      value={checkBoxValue(val)}
                                      indeterminate={
                                        checked === "indeterminate"
                                      }
                                    />
                                  )}
                                  {itemText(val, type)}
                                </p>
                              </div>
                              {type === "custom" &&
                                Array.isArray(val.value) &&
                                customList(val, type)}
                            </div>
                          );
                        })
                      ) : (
                        <p>Entity has no {title.toLowerCase()}</p>
                      )}
                    </List.Content>
                  </List.Item>
                );
              })}
          </List>
        </>
      ) : (
        <p className="no-entity">No entity selected</p>
      )}
    </div>
  );
}

export default function MergeEntitiesModal({ entities, finishMerge }) {
  const emptyEntity = { id: null, urls: [], info: {} };
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    srcEntity: { ...emptyEntity },
    dstEntity: { ...emptyEntity },
    mergedEntity: { ...emptyEntity },
  });
  const [curStep, setCurStep] = useState(0);
  const [campaigns, setCampaigns] = useState([]);
  const [checkReport, setCheckReport] = useState({});
  const [success, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const formPages = [
    {
      title: "Step 1 of 4: Basic Information",
      key: "basic-info",
    },
    {
      title: "Step 2 of 4: Campaigns + Tags",
      key: "campaigns-tags",
    },
    {
      title: "Step 3 of 4: Custom Fields",
      key: "customfields",
    },
  ];

  const entityOptions = entities.map(entity => ({
    text: entity.name,
    key: entity.id,
    value: entity.id,
  }));

  useEffect(() => {
    (async () => {
      const campaigns = await CampaignService.getCampaignsForFilters();
      setCampaigns(campaigns);
    })();
  }, []);
  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setCurStep(0);
    setFormData({
      srcEntity: { ...emptyEntity },
      dstEntity: { ...emptyEntity },
      mergedEntity: { ...emptyEntity },
    });
    setErrorMsg(null);
    setSuccess(false);
    finishMerge();
  };

  function extractValueAndSubvalue(dataValue) {
    if (typeof dataValue === "object") {
      return [dataValue, null];
    } else if ((dataValue ?? "").includes("|")) {
      return dataValue.split("|");
    } else {
      return [dataValue, null];
    }
  }

  const handleChange = (event, data) => {
    const formDataCopy = { ...formData };
    if (data.type === "checkbox") {
      let [key, id] = data.name.split("-");
      id = parseInt(id);
      const [value, subvalue] = extractValueAndSubvalue(data.value);
      let { srcEntity, dstEntity, mergedEntity } = formDataCopy;

      if (Array.isArray(mergedEntity[key])) {
        // Set fields with multiple values
        if (key === "urls") {
          if (data.checked) {
            mergedEntity[key].push(data.value);
          } else {
            mergedEntity[key] = mergedEntity[key].filter(v => v !== data.value);
          }
        } else {
          if (data.checked) {
            let newValue;
            const srcValues = [
              ...(srcEntity[key] || []),
              ...(dstEntity[key] || []),
            ];
            newValue = {
              ...srcValues.find(v => v.id === id || v.user_id === id),
            };
            mergedEntity[key].push(newValue);
          } else {
            mergedEntity[key] = mergedEntity[key].filter(v => {
              if (id) {
                return v.user_id ? v.user_id !== id : v.id !== id;
              }
              return v !== value;
            });
          }
        }
      } else if (key === "custom") {
        const newFieldValue = entities.find(c => c.id === id).custom_fields[
          value
        ];
        if (data.checked) {
          if (Array.isArray(newFieldValue)) {
            const customFields = mergedEntity.custom_fields?.[value] ?? [];
            if (subvalue) {
              mergedEntity.custom_fields[value] = [...customFields, subvalue];
            } else {
              mergedEntity.custom_fields[value] = newFieldValue.reduce(
                (accum, cur) => {
                  if (!customFields.includes(cur)) {
                    return [...accum, cur];
                  }
                  return accum;
                },
                customFields
              );
            }
          } else {
            // Get custom field value from entity
            mergedEntity.custom_fields[value] = newFieldValue;
          }
        } else {
          if (Array.isArray(newFieldValue)) {
            if (subvalue) {
              mergedEntity.custom_fields[value] = mergedEntity.custom_fields[
                value
              ].filter(item => item !== subvalue);
            } else {
              mergedEntity.custom_fields[value] = mergedEntity.custom_fields[
                value
              ].filter(item => !newFieldValue.includes(item));
            }
          } else {
            delete mergedEntity.custom_fields[value];
          }
        }
      } else {
        mergedEntity[key] = value === undefined ? data.checked : value;
        if (key === "entity_name") {
          mergedEntity.entity_id = entities.find(
            c => c[key] === mergedEntity[key]
          ).entity_id;
        }
      }
    } else {
      formDataCopy[event.target.name] = event.target.value;
    }
    setFormData(formDataCopy);
  };

  const handleSelect = (e, { name, value }) => {
    const formDataCopy = { ...formData };
    if (name === "srcEntity" || name === "dstEntity") {
      formDataCopy[name] = entities.find(entity => entity.id === value);
      formDataCopy[name].urls = formDataCopy[name].urls || [];
      if (name === "dstEntity") {
        formDataCopy.mergedEntity = cloneDeep(formDataCopy[name]);
        formDataCopy.mergedEntity.urls = formDataCopy.mergedEntity.urls || [];
      }
    } else {
      formDataCopy[name] = value;
    }
    setFormData(formDataCopy);
  };

  const handleCheck = async e => {
    const data = {
      new_entity_id: formData.dstEntity.id,
      old_entity_id: formData.srcEntity.id,
      merge_data: formData.mergedEntity,
    };
    try {
      let checkReport = await EntityService.mergeEntitiesCheck(data);
      setCheckReport(checkReport);
      handleNextStep();
      //handleSubmit();
    } catch (e) {
      setErrorMsg(e.message);
    }
  };

  const getOldName = () => {
    let srcName = formData.srcEntity.name;
    let dstName = formData.dstEntity.name;
    let mergedName = formData.mergedEntity.name;
    return srcName === mergedName ? dstName : srcName;
  };

  const handleSubmit = async () => {
    const data = {
      new_entity_id: formData.dstEntity.id,
      old_entity_id: formData.srcEntity.id,
      merge_data: formData.mergedEntity,
    };
    data.merge_data["old_name"] = getOldName();
    try {
      await EntityService.mergeEntities(data);
      setSuccess(true);
    } catch (e) {
      setErrorMsg(e.message);
    }
  };

  const handleNextStep = () => {
    setCurStep(curStep + 1);
  };

  const handlePreviousStep = () => {
    setCurStep(curStep - 1);
    setErrorMsg(null);
    setCheckReport({});
  };

  const sortCustomFieldsBySharedFirst = (srcEntity, dstEntity) => {
    const srcCustomFields = srcEntity.custom_fields;
    const dstCustomFields = dstEntity.custom_fields;
    const srcKeys = Object.keys(srcCustomFields).sort();
    const dstKeys = Object.keys(dstCustomFields).sort();
    const sharedKeys = srcKeys.filter(k => dstKeys.includes(k));
    const sortedSrcKeys = [
      ...sharedKeys,
      ...srcKeys.filter(k => !sharedKeys.includes(k)),
    ];
    const sortedDstKeys = [
      ...sharedKeys,
      ...dstKeys.filter(k => !sharedKeys.includes(k)),
    ];
    const sortedSrcCustomFields = sortedSrcKeys.reduce(
      (obj, key) => ({ ...obj, [key]: srcCustomFields[key] }),
      {}
    );
    const sortedDstCustomFields = sortedDstKeys.reduce(
      (obj, key) => ({ ...obj, [key]: dstCustomFields[key] }),
      {}
    );
    const newSrcEntity = {
      ...srcEntity,
      info: {
        ...srcEntity.info,
        custom: sortedSrcCustomFields,
      },
      custom_fields: sortedSrcCustomFields,
    };
    const newDstEntity = {
      ...dstEntity,
      info: {
        ...dstEntity.info,
        custom: sortedDstCustomFields,
      },
      custom_fields: sortedDstCustomFields,
    };
    return [newSrcEntity, newDstEntity];
  };

  const renderCurStep = step => {
    let { srcEntity, dstEntity, mergedEntity } = formData;
    if (step === 0) {
      return (
        <>
          <Grid>
            <Grid.Row>
              <Grid.Column width={13}>
                <Form.Group className="entities-select" inline>
                  <Form.Select
                    inline
                    search
                    onChange={handleSelect}
                    value={srcEntity.id}
                    label="Merge entity"
                    name="srcEntity"
                    options={entityOptions}
                    placeholder="Select entity"
                  />
                  <Form.Select
                    inline
                    search
                    onChange={handleSelect}
                    value={dstEntity.id}
                    label="into entity"
                    name="dstEntity"
                    options={entityOptions.filter(
                      c => c.value !== srcEntity.id
                    )}
                    placeholder="Select entity"
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={3}>
                <Button
                  color="blue"
                  content="Start"
                  floated="right"
                  onClick={handleNextStep}
                  size="tiny"
                  className="long"
                  disabled={!srcEntity.id || !dstEntity.id}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={13}>
                <Grid columns={2} celled>
                  <Grid.Column>
                    <EntityInfo
                      entity={srcEntity}
                      header={`Entity ID #${srcEntity.id}`}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <EntityInfo
                      entity={dstEntity}
                      header={`Entity ID #${dstEntity.id}`}
                    />
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      );
    } else if (step <= formPages.length) {
      const page = step - 1;
      if (formPages[page].key === "customfields") {
        [srcEntity, dstEntity] = sortCustomFieldsBySharedFirst(
          srcEntity,
          dstEntity
        );
      }
      return (
        <Grid>
          <Grid.Row>
            <Grid.Column width={10}>
              <Header as="h3">{formPages[page].title}</Header>
            </Grid.Column>
            <Grid.Column width={6} className="merged-entity-preview">
              <Header as="h4">Preview</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row stretched>
            <Grid.Column width={10}>
              <Grid columns={2} celled>
                <Grid.Column>
                  <EntityInfo
                    entity={dstEntity}
                    infoType={formPages[page].key}
                    select={true}
                    header="Primary Entity"
                    handleChange={handleChange}
                    mergedEntity={mergedEntity}
                    campaigns={campaigns}
                  />
                </Grid.Column>
                <Grid.Column>
                  <EntityInfo
                    entity={srcEntity}
                    select={true}
                    infoType={formPages[page].key}
                    header="Secondary Entity"
                    handleChange={handleChange}
                    mergedEntity={mergedEntity}
                    campaigns={campaigns}
                  />
                </Grid.Column>
              </Grid>
            </Grid.Column>
            <Grid.Column width={6} className="merged-entity-preview">
              <Grid columns={1} celled>
                <Grid.Column>
                  <EntityInfo
                    entity={mergedEntity}
                    infoType={formPages[page].key}
                    header="Merged Entity"
                    campaigns={campaigns}
                    handleChange={handleChange}
                  />
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <div className="back-next-buttons">
                <Button
                  className="long"
                  size="tiny"
                  content="Back"
                  onClick={handlePreviousStep}
                />
                <Button
                  className="long"
                  size="tiny"
                  color="blue"
                  content="Next"
                  onClick={handleNextStep}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    } else if (step === formPages.length + 1) {
      return (
        <Grid>
          <Grid.Row>
            <Header as="h3">Step 4 of 5: Preview</Header>
            <Grid columns="equal" celled>
              {formPages.map(page => (
                <Grid.Column>
                  <EntityInfo
                    entity={mergedEntity}
                    infoType={page.key}
                    campaigns={campaigns}
                    mergedEdit={true}
                    handleChange={handleChange}
                  />
                </Grid.Column>
              ))}
            </Grid>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {!!errorMsg && (
                <Message
                  error
                  header="Entity merge failed"
                  content={errorMsg}
                />
              )}
              <div className="back-next-buttons">
                <Button
                  className="long"
                  size="tiny"
                  content="Back"
                  onClick={handlePreviousStep}
                />
                <Button
                  className="long"
                  size="tiny"
                  color="blue"
                  content="Pre-Merge Check"
                  onClick={handleCheck}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    } else if (!success) {
      return (
        <Grid>
          <Grid.Row>
            <>
              <Header as="h3">
                {!checkReport.success ? (
                  <>Please review the following issues before merging</>
                ) : (
                  <>Confirm Merge</>
                )}
              </Header>
              <Grid celled>
                <Grid.Column>
                  <List>
                    {checkReport.messages.map(msg => (
                      <List.Item>
                        <List.Content>{msg}</List.Content>
                      </List.Item>
                    ))}
                  </List>
                </Grid.Column>
              </Grid>
            </>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {!!errorMsg && (
                <Message
                  error
                  header="Entity merge failed"
                  content={errorMsg}
                />
              )}
              <div className="back-next-buttons">
                <Button
                  className="long"
                  size="tiny"
                  content="Back"
                  onClick={handlePreviousStep}
                />
                <Button
                  className="long"
                  size="tiny"
                  color="blue"
                  disabled={!checkReport.success}
                  content="Merge"
                  onClick={handleSubmit}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    } else {
      return (
        <div>
          <p>Success!</p>
          <p>
            <Link to={`/entities/${dstEntity.id}`}>View merged entity</Link>
          </p>
        </div>
      );
    }
  };

  return (
    <Modal
      onClose={handleClose}
      closeOnDimmerClick={false}
      closeIcon
      open={modalOpen}
      trigger={
        <Button onClick={handleOpen} size="mini">
          Merge Entities
        </Button>
      }
    >
      <Modal.Header>Merge Entities</Modal.Header>
      <Modal.Content>
        <Form error={!!errorMsg} className="merge-entities">
          {renderCurStep(curStep)}
        </Form>
      </Modal.Content>
    </Modal>
  );
}
