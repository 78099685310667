import React, { useState, useEffect } from "react";
import cloneDeep from "lodash/cloneDeep";
import {
  Modal,
  Grid,
  Form,
  Checkbox,
  Button,
  Header,
  List,
  Message,
  Dropdown,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import ContactService from "../../../services/Contact";
import CampaignService from "../../../services/Campaign";
import "./MergeContactsModal.scoped.scss";

const VALUE_KEY_MAP = {
  email_addresses: "email",
  tags: "name",
  phone_numbers: "number",
  campaign_targets: "campaign_id",
};

function ContactInfo({
  contact,
  infoType,
  select = false,
  header = undefined,
  handleChange,
  mergedContact = {},
  campaigns,
  mergedEdit,
  step,
}) {
  const {
    id,
    full_name,
    entity_name,
    email_addresses,
    phone_numbers,
    campaign_targets,
    tags,
    custom_fields,
    alternate_names,
  } = contact;

  let info;

  switch (infoType) {
    case "campaigns-tags":
      info = [
        {
          title: "Campaigns",
          type: "campaign_targets",
          value: campaign_targets,
        },
        { title: "Tags", type: "tags", value: tags.filter(tag => !tag.auto) },
      ];
      break;
    case "customfields-preferences":
      info = [
        {
          title: "Custom Fields",
          type: "custom",
          value: Object.keys(custom_fields).map(k => ({
            field: k,
            value: custom_fields[k],
            contact_id: id,
          })),
        },
      ];
      break;
    case "basic-info":
    default:
      info = [
        { title: "Name", type: "full_name", value: full_name },
        { title: "Entity", type: "entity_name", value: entity_name },
        {
          title: `Email Addresses ${step === 4 ? "- Primary" : ""}`,
          type: "email_addresses",
          value: email_addresses || [],
        },
        {
          title: `Phone Numbers ${step === 4 ? "- Primary" : ""}`,
          type: "phone_numbers",
          value: phone_numbers || [],
        },
        {
          title: "Alternate Names",
          type: "alternate_names",
          value: alternate_names || [],
        },
      ];
  }

  const checkBoxName = (value, type) => {
    let id = value.id || value.campaign_id || value.contact_id;
    return `${type}${id ? `-${id}` : ""}`;
  };

  const checkBoxValue = (value, subvalue) => {
    let val = value.name || value.email || value.number || value.field || value;
    if (subvalue) {
      val += `|${subvalue}`;
    }
    return val;
  };

  const itemText = (value, type) => {
    switch (type) {
      case "tags":
      case "email_addresses":
      case "phone_numbers":
        return `${value[VALUE_KEY_MAP[type]]}${value.is_primary ? "*" : ""}`;
      case "campaign_targets":
        return (campaigns.find(c => c.id == value.campaign_id) || {}).name;
      case "custom":
        return `${value.field}: ${
          Array.isArray(value.value) ? "" : value.value
        }`;
      case "alternate_names":
        return value.name;
      default:
        return value;
    }
  };

  const customList = (value, type) => {
    return (
      <div className="custom-list">
        {value.value.toSorted().map((val, index) => (
          <p key={index}>
            {select && (
              <Checkbox
                onChange={handleChange}
                name={checkBoxName(value, type)}
                checked={isChecked(value, type, val)}
                value={checkBoxValue(value.field, val)}
              />
            )}{" "}
            {val}
          </p>
        ))}
      </div>
    );
  };

  const isChecked = (value, type, subvalue) => {
    if (Array.isArray(mergedContact[type])) {
      if (value.id || value.campaign_id) {
        return !!mergedContact[type].find(v =>
          v.campaign_id
            ? v.campaign_id === value.campaign_id
            : v.id === value.id
        );
      }
      return !!mergedContact[type].find(v => {
        return v === value;
      });
    } else {
      if (type === "custom") {
        if (Array.isArray(value.value)) {
          const customFields = mergedContact.custom_fields?.[value.field] ?? [];
          if (subvalue) {
            return customFields.includes(subvalue);
          }
          const allOn = value.value.every(val => customFields.includes(val));
          const allOff = value.value.every(val => !customFields.includes(val));
          return !allOn && !allOff ? "indeterminate" : allOn;
        }
        return (
          mergedContact.custom_fields &&
          !!mergedContact.custom_fields[value.field] &&
          mergedContact.custom_fields[value.field] === value.value
        );
      }
      if (type === "preferences") {
        return mergedContact[value.field] == value.value;
      }
      return mergedContact[type] === value;
    }
  };

  return (
    <div className="contact-info">
      {!!contact.id ? (
        <>
          {!!header && (
            <Header as="h4" block>
              {header}
            </Header>
          )}
          <List>
            {info
              .map(item => ({
                ...item,
                value: Array.isArray(item.value) ? item.value : [item.value],
              }))
              .map(({ title, value, type }, index) => {
                value = value.filter(
                  v => v && (!Array.isArray(v.value) || v.value.length > 0)
                ); // remove empty or null values
                return (
                  <List.Item key={index}>
                    <List.Header>{title}</List.Header>
                    <List.Content>
                      {value.length > 0 ? (
                        value.map((val, index) => {
                          const checked = isChecked(val, type);
                          return (
                            <div key={index} className={`info-item ${type}`}>
                              <div>
                                <p>
                                  {select && (
                                    <Checkbox
                                      onChange={handleChange}
                                      name={checkBoxName(val, type)}
                                      checked={checked}
                                      value={checkBoxValue(val)}
                                      indeterminate={
                                        checked === "indeterminate"
                                      }
                                    />
                                  )}
                                  {itemText(val, type)}
                                </p>
                              </div>
                              {type === "custom" &&
                                Array.isArray(val.value) &&
                                customList(val, type)}
                              {mergedEdit &&
                                (type == "email_addresses" ||
                                  type == "phone_numbers") && (
                                  <Checkbox
                                    onChange={handleChange}
                                    name={`${type}_is_primary-${val.id}`}
                                    toggle
                                    className="is-primary"
                                    checked={val.is_primary}
                                  />
                                )}
                            </div>
                          );
                        })
                      ) : (
                        <p>Contact has no {title.toLowerCase()}</p>
                      )}
                    </List.Content>
                  </List.Item>
                );
              })}
          </List>
        </>
      ) : (
        <p className="no-contact">No contact selected</p>
      )}
    </div>
  );
}

export default function MergeContactsModal({ contacts, finishMerge }) {
  const emptyContact = { id: null, info: {} };
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    srcContact: { ...emptyContact },
    dstContact: { ...emptyContact },
    mergedContact: { ...emptyContact },
  });
  const [curStep, setCurStep] = useState(0);
  const [campaigns, setCampaigns] = useState([]);
  const [success, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const formPages = [
    {
      title: "Step 1 of 4: Basic Information",
      key: "basic-info",
    },
    {
      title: "Step 2 of 4: Campaigns + Tags",
      key: "campaigns-tags",
    },
    {
      title: "Step 3 of 4: Custom Fields + Preferences",
      key: "customfields-preferences",
    },
  ];

  const contactOptions = contacts.map(contact => ({
    text: contact.full_name,
    key: contact.id,
    value: contact.id,
  }));

  useEffect(() => {
    (async () => {
      const campaigns = await CampaignService.getCampaignsForFilters();
      setCampaigns(campaigns);
    })();
  }, []);
  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setCurStep(0);
    setFormData({
      srcContact: { ...emptyContact },
      dstContact: { ...emptyContact },
      mergedContact: { ...emptyContact },
    });
    setErrorMsg(null);
    setSuccess(false);
    finishMerge();
  };

  const handleChange = (event, data) => {
    const formDataCopy = { ...formData };
    if (data.type == "checkbox") {
      const [key, id] = data.name.split("-");
      const [value, subvalue] = (data.value ?? "").includes("|")
        ? data.value.split("|")
        : [data.value, null];
      let { srcContact, dstContact, mergedContact } = formDataCopy;

      if (key.endsWith("_is_primary")) {
        // Set primary email / number
        let _key = key.replace("_is_primary", "");
        mergedContact[_key] = mergedContact[_key].map(v => {
          v.is_primary = v.id == id && data.checked;
          return v;
        });
      } else if (Array.isArray(mergedContact[key])) {
        // Set fields with multiple values
        if (data.checked) {
          let newValue = {};
          const srcValues = [
            ...(srcContact[key] || []),
            ...(dstContact[key] || []),
          ];
          newValue = {
            ...srcValues.find(v =>
              v.campaign_id ? v.campaign_id == id : v.id == id
            ),
          };
          if (newValue.is_primary) {
            newValue.is_primary = !mergedContact[key].find(v => v.is_primary);
          }
          mergedContact[key].push(newValue);
        } else {
          mergedContact[key] = mergedContact[key].filter(v => {
            if (id) {
              return v.campaign_id ? v.campaign_id != id : v.id != id;
            }
            return v !== value;
          });
        }
      } else if (key == "custom") {
        const newFieldValue = contacts.find(c => c.id == id).custom_fields[
          value
        ];
        if (data.checked) {
          // Get custom field value from contact
          if (Array.isArray(newFieldValue)) {
            const customFields = mergedContact.custom_fields?.[value] ?? [];
            if (subvalue) {
              mergedContact.custom_fields[value] = [...customFields, subvalue];
            } else {
              mergedContact.custom_fields[value] = newFieldValue.reduce(
                (accum, cur) => {
                  if (!customFields.includes(cur)) {
                    return [...accum, cur];
                  }
                  return accum;
                },
                customFields
              );
            }
          } else {
            mergedContact.custom_fields[value] = newFieldValue;
          }
        } else {
          if (Array.isArray(newFieldValue)) {
            if (subvalue) {
              mergedContact.custom_fields[value] = mergedContact.custom_fields[
                value
              ].filter(item => item !== subvalue);
            } else {
              mergedContact.custom_fields[value] = mergedContact.custom_fields[
                value
              ].filter(item => !newFieldValue.includes(item));
            }
          } else {
            delete mergedContact.custom_fields[value];
          }
        }
      } else if (key == "preferences") {
        if (data.checked) {
          mergedContact[value] = contacts.find(c => c.id == id)[value];
        }
      } else {
        mergedContact[key] = value == undefined ? data.checked : value;
        if (key == "entity_name") {
          mergedContact.entity_id = contacts.find(
            c => c[key] == mergedContact[key]
          ).entity_id;
        }
      }
    } else {
      formDataCopy[event.target.name] = event.target.value;
    }

    setFormData(formDataCopy);
  };

  const handleSelect = (e, { name, value }) => {
    const formDataCopy = { ...formData };
    if (name == "srcContact" || name == "dstContact") {
      formDataCopy[name] = contacts.find(contact => contact.id == value);
      if (name == "dstContact") {
        formDataCopy.mergedContact = cloneDeep(formDataCopy[name]);
      }
    } else {
      formDataCopy[name] = value;
    }
    setFormData(formDataCopy);
  };

  const handleSubmit = async e => {
    const data = {
      new_contact_id: formData.dstContact.id,
      old_contact_id: formData.srcContact.id,
      merge_data: formData.mergedContact,
    };
    try {
      await ContactService.mergeContacts(data);
      setSuccess(true);
    } catch (e) {
      setErrorMsg(e.message);
    }
  };

  const handleSuccess = async () => {
    handleClose();
  };

  const handleNextStep = () => {
    if (curStep === 0) {
      setFormData(prev => ({
        ...prev,
        mergedContact: {
          ...prev.mergedContact,
          email_addresses: [
            ...prev.mergedContact.email_addresses,
            ...prev.srcContact.email_addresses,
          ],
          phone_numbers: [
            ...prev.mergedContact.phone_numbers,
            ...prev.srcContact.phone_numbers,
          ],
        },
      }));
    } else if (curStep === 3) {
      setFormData(prev => {
        prev.mergedContact.email_addresses.forEach(email => {
          email.is_primary = false;
        });
        prev.mergedContact.phone_numbers.forEach(phone => {
          phone.is_primary = false;
        });
        return {
          ...prev,
        };
      });
    }
    setCurStep(prev => prev + 1);
  };

  const handlePreviousStep = () => {
    setCurStep(curStep - 1);
    setErrorMsg(null);
  };
  const renderCurStep = step => {
    const { srcContact, dstContact, mergedContact } = formData;

    if (step == 0) {
      return (
        <>
          <Grid>
            <Grid.Row>
              <Grid.Column width={13}>
                <Form.Group className="contacts-select" inline>
                  <Form.Select
                    inline
                    search
                    onChange={handleSelect}
                    value={srcContact.id}
                    label="Merge contact"
                    name="srcContact"
                    options={contactOptions}
                    placeholder="Select contact"
                  />
                  <Form.Select
                    inline
                    search
                    onChange={handleSelect}
                    value={dstContact.id}
                    label="into contact"
                    name="dstContact"
                    options={contactOptions.filter(
                      c => c.value != srcContact.id
                    )}
                    placeholder="Select contact"
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={3}>
                <Button
                  color="blue"
                  content="Start"
                  floated="right"
                  onClick={handleNextStep}
                  size="tiny"
                  className="long"
                  disabled={!srcContact.id || !dstContact.id}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={13}>
                <Grid columns={2} celled>
                  <Grid.Column>
                    <ContactInfo
                      contact={srcContact}
                      header={`Contact ID #${srcContact.id}`}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <ContactInfo
                      contact={dstContact}
                      header={`Contact ID #${dstContact.id}`}
                    />
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      );
    } else if (step <= formPages.length) {
      const page = step - 1;
      return (
        <Grid>
          <Grid.Row>
            <Grid.Column width={10}>
              <Header as="h3">{formPages[page].title}</Header>
            </Grid.Column>
            <Grid.Column width={6} className="merged-contact-preview">
              <Header as="h4">Preview</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row stretched>
            <Grid.Column width={10}>
              <Grid columns={2} celled>
                <Grid.Column>
                  <ContactInfo
                    contact={dstContact}
                    infoType={formPages[page].key}
                    select={true}
                    header="Primary Contact"
                    handleChange={handleChange}
                    mergedContact={mergedContact}
                    campaigns={campaigns}
                  />
                </Grid.Column>
                <Grid.Column>
                  <ContactInfo
                    contact={srcContact}
                    select={true}
                    infoType={formPages[page].key}
                    header="Secondary Contact"
                    handleChange={handleChange}
                    mergedContact={mergedContact}
                    campaigns={campaigns}
                  />
                </Grid.Column>
              </Grid>
            </Grid.Column>
            <Grid.Column width={6} className="merged-contact-preview">
              <Grid columns={1} celled>
                <Grid.Column>
                  <ContactInfo
                    contact={mergedContact}
                    infoType={formPages[page].key}
                    header="Merged Contact"
                    campaigns={campaigns}
                    handleChange={handleChange}
                  />
                  {mergedContact.phone_numbers.length === 0 &&
                    mergedContact.email_addresses.length === 0 && (
                      <Message
                        negative
                        content="At least one email address or phone number must be selected"
                      />
                    )}
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <div className="back-next-buttons">
                <Button
                  className="long"
                  size="tiny"
                  content="Back"
                  onClick={handlePreviousStep}
                />
                <Button
                  className="long"
                  size="tiny"
                  color="blue"
                  content="Next"
                  onClick={handleNextStep}
                  disabled={
                    mergedContact.email_addresses.length === 0 &&
                    mergedContact.phone_numbers.length === 0
                  }
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    } else if (!success) {
      return (
        <Grid>
          <Grid.Row>
            <Header as="h3">Step 4 of 4: Preview + Confirm Merge</Header>
            <Grid columns="equal" celled>
              {formPages.map(page => (
                <Grid.Column>
                  <ContactInfo
                    step={step}
                    contact={mergedContact}
                    infoType={page.key}
                    campaigns={campaigns}
                    mergedEdit={true}
                    handleChange={handleChange}
                  />
                </Grid.Column>
              ))}
            </Grid>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {!!errorMsg && (
                <Message
                  error
                  header="Contact merge failed"
                  content={errorMsg}
                />
              )}
              <div className="back-next-buttons">
                <Button
                  className="long"
                  size="tiny"
                  content="Back"
                  onClick={handlePreviousStep}
                />
                <Button
                  className="long"
                  size="tiny"
                  color="blue"
                  content="Merge"
                  onClick={handleSubmit}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    } else {
      return (
        <div>
          <p>Success!</p>
          <p>
            <Link to={`/contacts/${dstContact.id}`}>View merged contact</Link>
          </p>
        </div>
      );
    }
  };

  return (
    <Modal
      onClose={handleClose}
      closeOnDimmerClick={false}
      closeIcon
      open={modalOpen}
      trigger={
        <Dropdown.Item onClick={handleOpen} size="mini">
          Merge Contacts
        </Dropdown.Item>
      }
    >
      <Modal.Header>Merge Contacts</Modal.Header>
      <Modal.Content>
        <Form error={!!errorMsg} className="merge-contacts">
          {renderCurStep(curStep)}
        </Form>
      </Modal.Content>
    </Modal>
  );
}
