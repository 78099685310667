import React from "react";
import {
  Checkbox,
  Container,
  Dropdown,
  Icon,
  List,
  Segment,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { capitalize } from "components/helpers";
import revOppsConstants from "constants/RevenueOpportunity";
import "react-table-6/react-table.css";
import "react-table-hoc-draggable-columns/dist/styles.css";

import tablesSettings from "constants/Table";
import "../styles/table.scss";
import "../styles/tab_view.scss";

import BaseTable from "./BaseTable";
import AuthService from "../services/Auth";
import EntityService from "../services/Entity";
import ACL_RELATIONSHIPS from "../acl-relationships";
import withRoleCheck from "./hocs/withRoleCheck";
import CustomDropdown from "./CustomDropdown";
import setPageTitle from "../helpers/title";
import RevenueOpportunityService from "../services/RevenueOpportunities";
import _RevenueOpportunityModal from "./modals/RevenueOpportunityModal";
import _DeleteRevenueOpportunityModal from "./modals/DeleteRevenueOpportunityModal";
import CampaignService from "services/Campaign";
import TagList from "components/TagList";
import _RevenueOpportunityStatusModal from "../routes/campaign/components/RevenueOpportunityStatusModal";
import toInteger from "lodash/toInteger";
import moment from "moment/moment";
import CaseService from "services/Case";
import RecipientService from "services/Recipient";
import ProductService from "services/Product";
import isString from "lodash/isString";

const RevenueOpportunityModal = withRoleCheck(_RevenueOpportunityModal, [
  ACL_RELATIONSHIPS.revenueOpportunity.edit,
  ACL_RELATIONSHIPS.revenueOpportunity.create,
]);

const DeleteRevenueOpportunityModal = withRoleCheck(
  _DeleteRevenueOpportunityModal,
  [ACL_RELATIONSHIPS.revenueOpportunity.delete]
);

const RevenueOpportunityStatusModal = withRoleCheck(
  _RevenueOpportunityStatusModal,
  [ACL_RELATIONSHIPS.revenueOpportunityStatus.edit]
);

function getLineItemDefault(item) {
  const productName = item.product_name;
  const amount = Number(item.amount).toFixed(2);
  const term = item.term;
  const quantity = item.quantity;
  const licenseStartDate = item.license_start_date;
  let value = `${productName} - $${amount} - ${term} - ${quantity}`;
  if (licenseStartDate) {
    value += ` - ${licenseStartDate}`;
  }
  return value;
}

const AttachmentIcon = ({ s3Urls, opportunityId }) => {
  const handleClick = async () => {
    await RevenueOpportunityService.getRevenueOpportunityAttachments(
      opportunityId
    );
  };

  if (!s3Urls || s3Urls.length === 0) {
    return null;
  }

  return <Icon name="paperclip" link onClick={handleClick} />;
};

const getItem = item => {
  let mapping = {
    is_penalty: `Penalty - $${Number(item.amount).toFixed(2)}`,
    is_discount: `Discount - $${Number(item.amount).toFixed(2)}`,
    default: getLineItemDefault(item),
  };
  for (let key in mapping) {
    if (item[key]) {
      return mapping[key];
    }
  }
  return mapping.default;
};

class RevenueOpportunitiesTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = RevenueOpportunityService.getRevenueOpportunitiesTable;

    this.state = {
      ...this.state,
      defaultFilterParams: {
        case_id: this.props.caseId,
        entity_id: this.props.entityId,
        campaign_id: this.props.campaignId,
      },
      header: "Revenue Opportunities",
      enableTags: true,
      className: "RevenueOpportunity",
      headerIcon: "building",
      tableName: "revenue_opportunity",
      noDataText: "No Opportunities found. Try adjusting your filters.",
      statusOptions: [],
      campaignOptions: [],
      revenueOpportunityManagerOptions: [],
      disableModal: true,
      entityOptions: [],
      recipientTypeOptions: [],
      purchaseOrdersOptions: [],
      salesOrdersOptions: [],
      invoicesOptions: [],
      createButton: (
        <RevenueOpportunityModal
          onSuccess={this.handleOnSuccess}
          entityId={this.props.entityId}
          campaignId={this.props.campaignId}
          caseId={this.props.caseId}
        />
      ),
      enableCustomFieldsSettings: true,
      exportTableName: "RevenueOpportunitiesTable",
    };
    this.handleChangeWithUpdate = this.handleChangeWithUpdate.bind(this);
  }

  fetchRevenueOpportunityStatus = async () => {
    let options =
      await RevenueOpportunityService.getRevenueOpportunityStatusesForFilters();
    let optionsArr = [];
    if (options) {
      options.map(option => {
        let formOption = {
          id: option.id,
          text: option.name,
          value: option.id,
          name: option.name,
        };
        optionsArr.push(formOption);
      });
      this.setState({ statusOptions: optionsArr }, this.updateFilterOptions);
    }
  };

  fetchEntities = async () => {
    const options = await EntityService.getEntitiesForFilters();
    const optionsArr = options.map(option => ({
      id: option.id,
      name: option.name,
      value: option.id,
    }));
    this.setState({ entityOptions: optionsArr }, this.updateFilterOptions);
  };

  prepareRecipientTypeOptions = () => {
    const options = Object.keys(revOppsConstants.recipientType).map(
      (option, index) => ({
        id: option,
        name: capitalize(revOppsConstants.recipientType[option]),
        value: option,
      })
    );
    this.setState({ recipientTypeOptions: options }, this.updateFilterOptions);
  };

  fetchPurchaseOrdersOptions = async () => {
    this.fetchListField(
      RevenueOpportunityService.getPurchaseOrders,
      "purchaseOrdersOptions"
    );
  };

  fetchSalesOrdersOptions = async () => {
    this.fetchListField(
      RevenueOpportunityService.getSalesOrders,
      "salesOrdersOptions"
    );
  };

  fetchInvoicesOptions = async () => {
    this.fetchListField(
      RevenueOpportunityService.getInvoices,
      "invoicesOptions"
    );
  };

  fetchListField = async (callFunction, stateFieldName) => {
    const values = await callFunction();
    const optionsArr =
      values?.map(value => ({
        id: value,
        name: value,
        value: value,
      })) || [];
    this.setState({ [stateFieldName]: optionsArr }, this.updateFilterOptions);
  };

  fetchCampaigns = async () => {
    if (this.props.entityId) {
      let options = await EntityService.getCampaigns(this.props.entityId);
      const optionsArr = options.map(option => ({
        id: option.campaign_id,
        name: option.campaign_name,
        value: option.campaign_id,
      }));
      optionsArr.unshift({
        id: "None",
        name: "None",
      });

      this.setState({ campaignOptions: optionsArr }, this.updateFilterOptions);
    } else {
      const options = await CampaignService.getCampaignsForFilters({
        has_revenue_opportunities: true,
      });

      const optionsArr = options.map(option => ({
        id: option.id,
        name: option.name,
        value: option.id,
      }));
      this.setState({ campaignOptions: optionsArr }, this.updateFilterOptions);
    }
  };

  fetchAccountManagerOptions = async () => {
    const revenueOpportunityManagers =
      await RevenueOpportunityService.getRevenueOpportunityManagers();
    const revenueOpportunityManagersArr = revenueOpportunityManagers
      .filter(user => user.id > 0)
      .map(({ id, full_name, deleted }) => ({
        id: id,
        name: deleted ? `${full_name} (DELETED)` : full_name,
        value: id,
      }));
    this.setState(
      { revenueOpportunityManagerOptions: revenueOpportunityManagersArr },
      this.updateFilterOptions
    );
  };

  fetchCustomFields = async () => {
    const customFields = await RevenueOpportunityService.getCustomFields();
    this.setState(
      {
        customFields: [
          {
            accessorPrefix: "custom_fields",
            headerPrefix: "Revenue Opportunities",
            fields: customFields,
            model: "RevenueOpportunity",
          },
        ],
      },
      this.updateFilterOptions
    );
  };

  handleOnSuccess = async () => {
    await this.fetchRevenueOpportunityStatus();
    await this.fetchData();
  };

  revenueOpportunitiesHaveTheSameCampaign = () => {
    const { checkedArr, rows } = this.state;

    if (checkedArr.length === 0) {
      return false;
    }

    const campaignIds = checkedArr.map(id => {
      const row = rows.find(row => row.id === id);
      return row?.campaign_id;
    });

    const uniqueCampaignIds = new Set(campaignIds);
    if (uniqueCampaignIds.size === 1) {
      const campaignId = uniqueCampaignIds.values().next().value;
      this.setState({ campaignId });
      return true;
    }
    return false;
  };

  handleChangeWithUpdate(e, data) {
    this.handleChange(e, data);
    this.updateDisableModal();
  }

  deleteRevenueOpportunity = async revenueOpportunityId => {
    await RevenueOpportunityService.deleteRevenueOpportunity(
      revenueOpportunityId
    );
    this.fetchData();
  };

  updateFilterOptions = async () => {
    const {
      customFields,

      campaignOptions,

      statusOptions,
      entityOptions,
      recipientTypeOptions,
      tags,
      purchaseOrdersOptions,
      salesOrdersOptions,
      invoicesOptions,
      revenueOpportunityManagerOptions,
    } = this.state;

    let filters = [
      {
        key: "entity_id",
        title: "Entity",
        type: "ruvixxSelect",
        queryFn: EntityService.getEntitiesForFilters,
        props: {
          includeNoneOption: true,
        },
      },
      {
        key: "campaign_id",
        title: "Campaign",
        type: "ruvixxSelect",
        queryFn: async () =>
          await CampaignService.getCampaignsForFilters({
            has_revenue_opportunities: true,
          }),
        props: {
          includeNoneOption: true,
        },
      },
      {
        key: "case_id",
        title: "Case",
        type: "ruvixxSelect",
        queryFn: CaseService.getForFilters,
        props: {
          includeNoneOption: true,
        },
      },
      {
        key: "status_id",
        title: "Status",
        type: "select",
        data: statusOptions,
      },
      {
        key: "effective_date",
        title: "Effective Date",
        type: "dateRange",
      },
      {
        key: "recipient_type",
        title: "Recipient Type",
        type: "select",
        data: recipientTypeOptions,
      },
      {
        key: "recipient_id",
        title: "Recipient Name",
        type: "ruvixxSelect",
        queryFn: RecipientService.getForFilters,
      },
      {
        key: "purchase_order_num",
        title: "P.O.#",
        type: "select",
        data: purchaseOrdersOptions,
      },
      {
        key: "sales_order_num",
        title: "S.O.#",
        type: "select",
        data: salesOrdersOptions,
      },
      {
        key: "sales_order_date",
        title: "S.O. Date",
        type: "dateRange",
      },
      {
        key: "invoice_num",
        title: "Invoice #",
        type: "select",
        data: invoicesOptions,
      },
      {
        key: "invoice_date",
        title: "Invoice Date",
        type: "dateRange",
      },
      {
        key: "product_id",
        title: "Product",
        type: "ruvixxSelect",
        queryFn: ProductService.getForFilters,
      },
      {
        key: "value",
        title: "Value",
        type: "range",
      },
      {
        key: "license_start_date",
        title: "License Start Date",
        type: "dateRange",
      },
      {
        key: "license_end_date",
        title: "License End Date",
        type: "dateRange",
      },
      {
        key: "tag_id",
        title: "Tag",
        type: "select",
        data: tags,
      },
      {
        key: "manager_id",
        title: "Revenue Opportunity Manager",
        type: "select",
        data: revenueOpportunityManagerOptions,
      },
    ];
    customFields.forEach(cf =>
      cf.fields.forEach(field => {
        filters.push({
          key: `search:custom:${cf.model}:${field}`,
          title: field,
          type: "input",
          model: cf.model,
        });
      })
    );
    this.setState({ filters });
  };

  async componentDidMount() {
    setPageTitle("Revenue Opportunities");
    if (AuthService.isLoggedIn()) {
      this.fetchData();
      this.fetchCustomFields();
      this.fetchRevenueOpportunityStatus();
      this.fetchCampaigns();
      this.fetchEntities();
      this.fetchTags();
      this.prepareRecipientTypeOptions();
      this.fetchPurchaseOrdersOptions();
      this.fetchSalesOrdersOptions();
      this.fetchInvoicesOptions();
      this.fetchAccountManagerOptions();
    }
  }

  setColumns = () => {
    const columns = [
      {
        Header: () => (
          <Checkbox
            onChange={this.onSelectAll}
            checked={this.state.allSelected}
          />
        ),
        resizable: false,
        sortable: false,
        headerClassName: "centered non-sortable",
        width: 40,
        className: "centered",
        Cell: ({ original: { id, full_name } }) => (
          <Checkbox
            onChange={this.handleChangeWithUpdate}
            name={full_name}
            id={id}
            checked={this.state.checked[id]}
          />
        ),
      },
      {
        Header: "Opportunity ID",
        accessor: "id",
        Cell: props => (
          <Link to={`/revenue_opportunities/${props.value}/activityLog`}>
            {props.value}
          </Link>
        ),
      },
      {
        Header: "Manager",
        accessor: "manager_name",
      },
      {
        Header: "Tags",
        accessor: "tags",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <TagList
            tags={props.value}
            modelType={this.state.className}
            modelId={props.original.id}
            onUpdate={this.fetchData}
            tableCell
          />
        ),
      },
      {
        Header: "Entity ID",
        accessor: "entity_id",
        Cell: ({ value }) => {
          return <Link to={"/entities/" + value}>{value}</Link>;
        },
      },
      {
        Header: "Entity",
        accessor: "entity_name",
        Cell: ({ original }) => {
          return (
            <Link to={"/entities/" + original.entity_id}>
              {original.entity_name}
            </Link>
          );
        },
      },
      {
        Header: "Case ID",
        accessor: "case.case_id",
        Cell: ({ original }) => {
          if (original.case?.id) {
            return (
              <Link to={"/cases/" + original.case?.id}>
                {original.case?.case_id}
              </Link>
            );
          }
          return null;
        },
      },
      {
        Header: "Case Name",
        accessor: "case.name",
        Cell: ({ original }) => {
          if (original.case?.id) {
            return (
              <Link to={"/cases/" + original.case?.id}>
                {original.case?.name}
              </Link>
            );
          }
          return null;
        },
      },
      {
        Header: "Campaign",
        accessor: "campaign_name",
        Cell: ({ original, value }) => {
          return value ? (
            <Link to={"/campaigns/" + original.campaign_id}>
              {original.campaign_name}
            </Link>
          ) : null;
        },
      },
      {
        Header: "Recipient Type",
        accessor: "recipient_type",
      },
      {
        Header: "Recipient Name",
        accessor: "recipient_name",
      },
      { Header: "Billing To", accessor: "billing_to" },
      {
        Header: "P.O. #",
        accessor: "purchase_order",
        Cell: ({ original, value }) => {
          return value?.join(", ");
        },
      },
      {
        Header: "Invoice #",
        accessor: "invoice",
        Cell: ({ original, value }) => {
          return value?.join(", ");
        },
      },
      { Header: "Invoice Date", accessor: "invoice_date" },
      {
        Header: "S.O. #",
        accessor: "sales_order",
        Cell: ({ original, value }) => {
          return value?.join(", ");
        },
      },
      { Header: "S.O. Date", accessor: "sales_order_date" },
      {
        Header: "Status",
        minWidth: tablesSettings.statusColumnMinWidth,
        accessor: "status_name",
        className: "centered",
        Cell: ({ value, original }) => {
          return (
            <>
              {isString(value) ? (
                <strong style={{ color: "black" }}>{value}</strong>
              ) : (
                <span style={{ color: "grey" }}>(unset)</span>
              )}
              <RevenueOpportunityStatusModal
                hideStatusName
                revenueOpportunityIds={[original.id]}
                campaignId={original.campaign_id}
                statusId={original.status_id}
                statusName={value}
                effectiveDate={original.effective_date}
                updateData={this.fetchData}
              />
            </>
          );
        },
      },
      {
        Header: "Effective Date",
        accessor: "effective_date",
        Cell: ({ value }) => {
          return !!value ? moment(value).format("YYYY-MM-DD") : "N/A";
        },
      },

      { Header: "Comments", accessor: "comments" },
      {
        Header: "Line Items",
        accessor: "invoices",
        className: "centered",
        sortable: false,
        headerClassName: "centered non-sortable",
        Cell: ({ value }) => {
          return (
            <List horizontal bulleted style={{ display: "flex" }}>
              {value.map((listItem, index) => (
                <List.Item key={index}>{getItem(listItem)}</List.Item>
              ))}
            </List>
          );
        },
      },
      {
        Header: "Total Value",
        accessor: "value",
        Cell: props => {
          return `$${props.value.toFixed(2)}`;
        },
      },
      {
        Header: "Attachments",
        accessor: "attachments",
        Cell: ({ value, original }) => {
          return <AttachmentIcon s3Urls={value} opportunityId={original.id} />;
        },
      },
      {
        Header: "Actions",
        id: "actions",
        width: 140,
        className: "centered",
        sortable: false,
        headerClassName: "centered non-sortable",
        Cell: props => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu
              direction={this.isColumnFreezed("Actions") ? "right" : "left"}
            >
              <RevenueOpportunityModal
                onSuccess={this.fetchData}
                entityId={this.props.entityId || props.original.entity_id}
                caseId={this.props.caseId || props.original.case_id}
                campaignId={this.props.campaignId || props.original.campaign_id}
                revenueOpportunity={props.original}
              />
              <RevenueOpportunityModal
                isClone
                onSuccess={this.fetchData}
                entityId={this.props.entityId || props.original.entity_id}
                caseId={this.props.caseId || props.original.case_id}
                campaignId={this.props.campaignId || props.original.campaign_id}
                revenueOpportunity={props.original}
              />
              <DeleteRevenueOpportunityModal
                onConfirmDelete={() =>
                  this.deleteRevenueOpportunity(props.original.id)
                }
                menuTrigger
              />
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  updateDisableModal = async () => {
    const sameCampaign = this.revenueOpportunitiesHaveTheSameCampaign();
    this.setState({ disableModal: !sameCampaign });
  };

  componentDidUpdate(_, prevState) {
    const prevChecked = JSON.stringify(prevState.checkedArr);
    const currentChecked = JSON.stringify(this.state.checkedArr);

    if (prevChecked !== currentChecked) {
      this.updateDisableModal();
    }
  }

  actionsDropdown() {
    const disableActions = this.state.checkedArr.length === 0;
    return (
      <Dropdown
        text="Actions"
        className="button mini"
        disabled={disableActions}
      >
        <Dropdown.Menu direction="left">
          <RevenueOpportunityStatusModal
            trigger={<Dropdown.Item content="Change Status" />}
            revenueOpportunityIds={Object.keys(this.state.checked).map(
              toInteger
            )}
            campaignId={this.props.campaignId || this.state.campaignId}
            updateData={this.fetchData}
            disabled={this.state.disableModal}
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  render() {
    if (this.props.nested) {
      return this.renderTable();
    } else {
      return (
        <Container fluid className="route">
          <div className="campaign-segment">
            <Segment>{this.renderTable()}</Segment>
          </div>
        </Container>
      );
    }
  }
}

export default RevenueOpportunitiesTable;
