import React, { useEffect, useRef, useState } from "react";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import unionBy from "lodash/unionBy";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

import usePrevious from "../hooks/usePrevious";

import "./RuvixxSelect.scope.scss";

const defaultMapOption = option => {
  const deleted = option.deleted;
  return {
    key: option.id,
    text:
      option.text ||
      option.name ||
      option.full_name ||
      (deleted ? "(DELETED)" : ""),
    value: option.id,
    disabled: option.deleted,
  };
};

function RuvixxSelect({
  name,
  value,
  onChange,
  multiple,
  inline = true,
  required,
  fluid,
  clearable,
  campaignIds,
  entityIds,
  caseIds,
  dialSessionIds,
  mapForCampaignId = false,
  placeholder,
  label,
  hideLabel,
  queryFn,
  sortParam = "text",
  className,
  allowAdditions,
  includeNoneOption = false,
  createFn,
  setValue,
  lazy = false,
  readOnly = false,
  additionalItems = [],
  mapOption = defaultMapOption,
}) {
  const [fetchedOptions, setFetchedOptions] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const hasQueryBeenExecuted = useRef();
  const initialized = useRef();
  const wasNull = useRef();
  const prevCampaignIds = usePrevious(campaignIds);
  const prevEntityIds = usePrevious(entityIds);
  const prevCaseIds = usePrevious(caseIds);
  const prevDialSessionIds = usePrevious(dialSessionIds);
  const prevName = usePrevious(name);
  const prevValue = usePrevious(value);
  const prevSearch = usePrevious(search);
  const prevQueryFn = usePrevious(queryFn);

  const handleSearchChange = async (e, { searchQuery }) => {
    setSearch(searchQuery);
  };

  const mapOptionWithCampaignId = option => ({
    key: option.id,
    text:
      (option.text || option.name || option.full_name) +
      " (Campaign: " +
      option.campaign_id +
      ")",
    value: option.id,
  });

  const noneOption = {
    id: "Null",
    key: "Null",
    text: "None",
    value: "Null",
  };

  function valueInOptions(newOptions) {
    if (Array.isArray(value)) {
      return value.every(v => newOptions.find(option => option.value === v));
    }
    return newOptions.find(option => option.value === value);
  }

  useEffect(() => {
    if (queryFn && (!lazy || search || value)) {
      const shouldFetch =
        !hasQueryBeenExecuted.current || checkAttributesForRefetch();
      if (shouldFetch) {
        fetchOptions();
      }
    }
  }, [
    value,
    search,
    campaignIds,
    caseIds,
    entityIds,
    dialSessionIds,
    name,
    queryFn,
  ]);

  const checkAttributesForRefetch = () => {
    return (
      !isEqual(prevCampaignIds, campaignIds) ||
      !isEqual(prevEntityIds, entityIds) ||
      !isEqual(prevCaseIds, caseIds) ||
      prevName !== name ||
      prevDialSessionIds !== dialSessionIds ||
      prevValue !== value ||
      prevSearch !== search
    );
  };

  const fetchOptions = async () => {
    wasNull.current = !value;
    const filters = {};
    setLoading(true);
    if (search) {
      filters["search_query"] = search;
    }

    if (campaignIds && campaignIds.length) {
      filters["campaign_ids"] = campaignIds;
    }
    if (entityIds && entityIds.length) {
      filters["entity_ids"] = entityIds;
    }
    if (caseIds && caseIds.length) {
      filters["entity_ids"] = caseIds;
    }

    if (dialSessionIds && dialSessionIds.length) {
      filters["dial_session_ids"] = dialSessionIds;
    }

    if (
      (!initialized.current || prevValue !== value) &&
      Number.isInteger(value)
    ) {
      filters["model_id"] = value;
      initialized.current = true;
    }

    const resp = await queryFn(filters);
    if (!hasQueryBeenExecuted.current) {
      hasQueryBeenExecuted.current = true;
    }
    if (!value && !wasNull.current) {
      return;
    }

    let newOptions = [];
    if (mapForCampaignId) {
      newOptions = resp.map(mapOptionWithCampaignId);
    } else {
      newOptions = resp.map(mapOption);
    }

    if (
      (!prevName || prevName === name) &&
      value &&
      !valueInOptions(newOptions)
    ) {
      const resp = await queryFn({ ...filters });
      newOptions = newOptions.concat(resp.map(mapOption));
    }

    newOptions = unionBy(
      newOptions,
      (additionalItems ?? []).map(({ id, name }) => ({
        key: id,
        text: name,
        value: id,
      })),
      "key"
    );

    // Don't keep previous options when campaign_ids change
    if (
      !isEqual(prevCampaignIds, campaignIds) ||
      !isEqual(prevEntityIds, entityIds) ||
      !isEqual(prevCaseIds, caseIds) ||
      prevName !== name ||
      prevDialSessionIds !== dialSessionIds ||
      prevQueryFn !== queryFn
    ) {
      newOptions = sortBy(newOptions, sortParam);
    } else {
      newOptions = fetchedOptions.concat(newOptions);
      newOptions = sortBy(newOptions, sortParam);
    }

    if (!valueInOptions(newOptions)) {
      onChange(null, { value: null });
    }

    setLoading(false);
    newOptions = uniqBy(newOptions, "value");
    if (includeNoneOption) {
      newOptions.unshift(noneOption);
    }
    newOptions = newOptions.filter(opt => !!opt.text);
    setFetchedOptions(newOptions);
    return newOptions;
  };

  function escapeRegExp(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#]/g, "\\$&");
  }

  const searchFn = (options, searchQuery) => {
    let filteredOptions = options;
    const re = new RegExp(
      escapeRegExp(searchQuery).split(/\s+/).join(".*"),
      "i"
    );
    filteredOptions = filteredOptions.filter(opt => re.test(opt.text.trim()));
    return filteredOptions;
  };

  const handleAdd = async (e, { value }) => {
    const newOption = await createFn(value);
    const newOptions = [...fetchedOptions, mapOption(newOption)];
    setFetchedOptions(newOptions);
    setValue(newOption);
  };

  const getClassName = () => {
    let styleClass = className || "";

    if (readOnly) {
      styleClass += " readOnly";
    }

    return styleClass;
  };

  return (
    <Form.Select
      inline={inline}
      required={required}
      placeholder={placeholder || "Type to search..."}
      clearable={clearable}
      label={!hideLabel ? label : null}
      name={name}
      value={value}
      search={searchFn}
      multiple={multiple}
      loading={loading}
      fluid={fluid}
      options={fetchedOptions}
      onChange={onChange}
      onSearchChange={debounce(handleSearchChange, 300)}
      className={getClassName()}
      allowAdditions={allowAdditions}
      onAddItem={handleAdd}
      selectOnBlur={!allowAdditions}
      selectOnNavigation={!allowAdditions}
    />
  );
}

const propTypesTestGenerator = (
  props,
  propName,
  componentName,
  propSecondName,
  expectedType
) => {
  if (!props[propName] && !props[propSecondName]) {
    return new Error(
      `One of '${propName}' or '${propSecondName}' is required by '${componentName}' component.`
    );
  }
  if (props[propName] && typeof props[propName] !== expectedType) {
    return new Error(
      `Invalid prop '${propName}' supplied to '${componentName}'. Expected a ${expectedType}.`
    );
  }
};

RuvixxSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  queryFn: (props, propName, componentName) =>
    propTypesTestGenerator(
      props,
      propName,
      componentName,
      "options",
      "function"
    ),
  options: (props, propName, componentName) =>
    propTypesTestGenerator(props, propName, componentName, "queryFn", "object"),
  label: PropTypes.string,
  hideLabel: PropTypes.bool,
  placeholder: PropTypes.string,
  campaignIds: PropTypes.arrayOf(PropTypes.number),
  dialSessionIds: PropTypes.arrayOf(PropTypes.number),
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  clearable: PropTypes.bool,
  additionalItems: PropTypes.arrayOf(PropTypes.object),
};

export default RuvixxSelect;
