import isEmpty from "lodash/isEmpty";
import startCase from "lodash/startCase";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Container, Divider, Icon, Menu, List, Form } from "semantic-ui-react";
import { Link, useNavigate, useParams } from "react-router-dom";

import Sidebar from "../../components/Sidebar";
import isNil from "lodash/isNil";
import { sum } from "lodash";
import _TagDropdown from "../../components/TagDropdown";
import AuditLog from "../../components/views/AuditLog";
import NotesView from "../../components/views/NotesView";
import { generateTabs } from "../../constants/Constants";
import { noTag } from "../../constants/Tag";
import setPageTitle from "../../helpers/title";
import { getInvoicesTotalValue } from "../../components/helpers";
import useRouteParam from "../../hooks/params/useRouteParam";
import useTags from "../../hooks/useTags";
import AuthService from "../../services/Auth";
import { SendEmailModal as _SendEmailModal } from "../campaign/components/Modals";
import ActivityService from "../../services/Activity";
import withRoleCheck from "components/hocs/withRoleCheck";
import { roundToTwoDecimals } from "components/helpers";
import ACL_RELATIONSHIPS from "acl-relationships";

import RevenueOpportunityService from "services/RevenueOpportunities";
import RevenueOpportunityInvoices from "components/RevenueOpportunityInvoices";
import _RevenueOpportunityModal from "components/modals/RevenueOpportunityModal";
import RecipientService from "services/Recipient";
import EntityService from "services/Entity";
import CaseService from "services/Case";
import UserService from "services/User";

const enabledTabs = ["activityLog", "invoices", "notes"];
const tabs = generateTabs(...enabledTabs);

const RevenueOpportunityModal = withRoleCheck(_RevenueOpportunityModal, [
  ACL_RELATIONSHIPS.revenueOpportunity.edit,
]);

const RevenueOpportunity = () => {
  const [focusOnNewNote, setFocusOnNewNote] = useState(false);
  const [auditLogId, setAuditLogId] = useState(null);
  const [model, setModel] = useState({});
  const [activeTab, setActiveTab] = useRouteParam("tab", tabs[0].name);
  const [revenueOpportunityId] = useRouteParam("id");
  const tags = useTags("revenue_opportunity");
  const [hasNotes, setHasNotes] = useState(false);
  const [updateAuditLogFlag, setUpdateAuditLogFlag] = useState(0);

  const [formData, setFormData] = useState({
    entity_id: model?.entity_id,
    campaign_id: model?.campaign_id,
    case_id: model?.case_id,
    status_id: model?.status_id || null,
    value: !isNil(model?.value) && model?.value !== "" ? model?.value : null,
    recipient_type: model?.recipient_type,
    recipient_id: model?.recipient_id,
    billing_to: model?.billing_to,
    purchase_order: model?.purchase_order || [],
    invoice: model?.invoice || [],
    invoice_date: model?.invoice_date,
    sales_order: model?.sales_order || [],
    sales_order_date: model?.sales_order_date,
    comments: model?.comments,
    invoices: model?.invoices,
    effective_date: model?.effective_date,
    attachments: model?.attachments || [],
  });

  const fetchNotes = useCallback(async () => {
    if (!revenueOpportunityId) return;
    const result = await ActivityService.hasNotes(
      revenueOpportunityId,
      "RevenueOpportunity"
    );
    setHasNotes(result);
  }, [revenueOpportunityId]);

  const updateFormData = newValues => {
    setFormData({
      ...formData,
      ...newValues,
    });
  };

  useEffect(() => {
    if (AuthService.isLoggedIn() && revenueOpportunityId) {
      fetchRevenueOpportunity();
      fetchNotes();
    }
  }, [revenueOpportunityId, fetchNotes]);

  const fetchManager = useCallback(async () => {
    const manager = await UserService.getUser(model.manager_id, true);
    const managerName = manager?.full_name;
    if (manager?.deleted_at) {
      model.deleted_manager = true;
      model.manager_name = `${managerName} (DELETED)`;
    } else {
      model.manager_name = managerName;
    }
    setModel({
      ...model,
      managerName,
    });
  }, [model.manager_id]);

  useEffect(() => {
    if (model.manager_id) {
      fetchManager();
    }
  }, [model.manager_id, fetchManager]);

  const fetchRevenueOpportunity = async () => {
    let model = await RevenueOpportunityService.getRevenueOpportunityById(
      revenueOpportunityId
    );
    model.deleted_manager = false;

    if (model.tags.length === 0) {
      model.tags = [noTag];
    }

    if (model.recipient_id) {
      const recipient = await RecipientService.get(model.recipient_id);
      model.recipient_name = recipient?.name;
    }

    const entity = await EntityService.getEntity(model.entity_id);
    model.entity_name = entity?.name;

    if (model.case_id) {
      const piracyCase = await CaseService.get(model.case_id);
      model.case_name = piracyCase?.name;
    }

    if (model.invoices) {
      model.invoice_total_value = `$${roundToTwoDecimals(
        getInvoicesTotalValue(model.invoices)
      )}`;
    }

    setModel(model);
    setPageTitle(`Revenue Opportunity ${model.id}`);
    setUpdateAuditLogFlag(updateAuditLogFlag + 1);
  };

  const handleTabChange = (
    e,
    { name, focusOnNewNote = false, auditLogId = null }
  ) => {
    setFocusOnNewNote(focusOnNewNote);
    setAuditLogId(auditLogId);
    setActiveTab(name);
  };

  const renderTabView = useMemo(() => {
    if (!revenueOpportunityId || isEmpty(model)) {
      return null;
    }
    switch (activeTab) {
      case "activityLog":
        return (
          <AuditLog
            modelId={revenueOpportunityId}
            modelType="revenue_opportunity"
            auditLogId={auditLogId}
            updateFlag={updateAuditLogFlag}
          />
        );
      case "invoices":
        return (
          <List className="form notes tabView ">
            <RevenueOpportunityInvoices
              readOnly
              revenueOpportunity={model}
              formData={{ comments: model.comments }}
              updateFormData={updateFormData}
            />
          </List>
        );
      case "notes":
        return (
          <NotesView
            modelType="RevenueOpportunity"
            modelId={revenueOpportunityId}
            focusOnNewNote={focusOnNewNote}
            handleTabChange={handleTabChange}
          />
        );

      default:
        return null;
    }
  }, [
    activeTab,
    revenueOpportunityId,
    model,
    auditLogId,
    focusOnNewNote,
    updateAuditLogFlag,
  ]);

  const renderSidebar = () => (
    <Sidebar
      modelName="revenue_opportunities"
      modelId={revenueOpportunityId}
      fetchModel={fetchRevenueOpportunity}
      icon="gem"
      name={model.id && `Revenue opportunity (${model.id})`}
      editButton={
        <RevenueOpportunityModal
          sidebarView
          onSuccess={fetchRevenueOpportunity}
          revenueOpportunity={model}
        />
      }
      info={[
        {
          title: "Revenue Opportunity ID",
          value: model.id,
        },
        {
          title: "Entity Name",
          value: (
            <Link to={`/entities/${model.entity_id}`}>{model.entity_name}</Link>
          ),
        },
        {
          title: "Case",
          value: model.case_name && (
            <Link to={`/cases/${model.case_id}`}>{model.case_name}</Link>
          ),
        },
        {
          title: "Manager",
          value: model.manager_name,
        },
        {
          title: "Recipient Type",
          value: model.recipient_type,
        },
        {
          title: "Recipient",
          value: model.recipient_name,
        },
        {
          title: "Billing To",
          value: model.billing_to,
        },
        {
          title: "Invoice #",
          value: model.invoice?.join(", "),
        },
        {
          title: "Invoice Date",
          value: model.invoice_date,
        },
        {
          title: "P. O. #",
          value: model.purchase_order?.join(", "),
        },
        {
          title: "S. O. #",
          value: model.sales_order?.join(", "),
        },
        {
          title: "S. O. Date",
          value: model.sales_order_date,
        },
        {
          title: "Status",
          value: model.status_full_name,
        },
        {
          title: "Effective Date",
          value: model.effective_date,
        },
        {
          title: "Invoice Total Value",
          value: model.invoice_total_value,
        },
        {
          value: model.tags,
          type: "tags",
          model,
          modelType: "RevenueOpportunity",
          tags,
        },
      ]}
      custom={model.custom_fields}
      handleTabChange={handleTabChange}
      campaignId={model.campaign_id}
      entity_id={model.entity_id}
    />
  );

  const renderTabMenu = () => (
    <Menu className="navbar icon-only" pointing secondary>
      {tabs.map(({ name, icon }) => {
        const isActive = activeTab === name;
        return (
          <Menu.Item
            key={name}
            name={name}
            active={isActive}
            onClick={handleTabChange}
          >
            <Icon name={icon} size="large" /> {isActive && startCase(name)}
            {name === "notes" && hasNotes && !isActive && (
              <div className="new-notif-indicator-contact" />
            )}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Container fluid className="route divided">
      {renderSidebar()}
      <div className="main">
        {renderTabMenu()}
        {renderTabView}
        <Divider hidden />
      </div>
    </Container>
  );
};

export default RevenueOpportunity;
